export const DEFAULTS_CLOUD = {
    save_token: false,
}
export const DEFAULTS_STACK = {
    commonDomain: '',
    domainList: {},
    ssl: 'letsencrypt',
    vars: [],
    integrated_features: [],
    extended_features: [],
    subscriptions: [],
    form_app: [],
}

export const DEFAULTS_SERVER = {
    region: null,
    zone: null,
    server: null,
    os: null,
    disk_type: 'pd-standart',
}

const DEFAULTS_BUILDER_PROJECT_NAME = 'New project';

export const DEFAULTS_BUILDER = {
    networks: [{name: 'default_network', id: generateRandomID()}],
    web: [],
    feature: [],
    service: [],
    custom_stack_category: null,
    custom_stack_code: generateSlug(DEFAULTS_BUILDER_PROJECT_NAME),
    custom_stack_description: null,
    custom_stack_short_description: null,
    project_description: null,
    project_git_url: null,
    project_name: DEFAULTS_BUILDER_PROJECT_NAME,
    project_overview: null,
}

let initialState = {
    stacks: [],
    apps: [],
    messages: [],
    messagesResponse: [],
    errorsInputs: {},
    // errorsComponents: {},
    currentApp: null,
    currentCloud: null,
    instanceData: {
        regions: null,
        regionsStatus: null,
        servers: null,
        serversStatus: null,
        images: null,
        imagesStatus: null,
    },
    featuresData: {
        features: [],
        dependencies: [],
    },
    requirements: {
        ram: 0,
        storage: 0,
        consumers: [],
    },
    requestLoading: false,
    debug: {},
    bodyBuilder: DEFAULTS_BUILDER,
    bodyCloud: DEFAULTS_CLOUD,
    bodyServer: DEFAULTS_SERVER,
    bodyStack: DEFAULTS_STACK,
};

import {
    SET_DEPLOYMENT_APPS,
    SET_DEPLOYMENT_APP,
    SET_DEPLOYMENT_CLOUD,
    UPDATE_FEATURES_DATA,
    UPDATE_REQUIREMENTS,
    UPDATE_REQUEST_LOADING,
    SWITCH_DEBUG_OPTION,
    WIPE_DEPLOYMENT_DATA,
    SET_DEPLOYMENT_STACKS,
    //
    UPDATE_INSTANCE_DATA,
    WIPE_INSTANCE_DATA,
    // request data
    UPDATE_BODY_STACK,
    UPDATE_BODY_BUILDER,
    WIPE_BODY_BUILDER,
    UPDATE_BODY_SERVER,
    WIPE_BODY_SERVER,
    UPDATE_BODY_CLOUD,
    WIPE_BODY_CLOUD,
    //
    UPDATE_INPUT_ERROR,
    SET_DEPLOYMENT_MESSAGES,
    SET_DEPLOYMENT_RESPONSE_MESSAGES,
} from '../actions/DeploymentActions'
import { generateSlug, generateRandomID } from "../../helpers/common";

let deploymentReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DEPLOYMENT_STACKS :
            return {
                ...state,
                ...{stacks: action.payload}
            };
        case SET_DEPLOYMENT_APPS :
            return {
                ...state,
                ...{apps: action.payload}
            };
        case SET_DEPLOYMENT_APP :
            return {
                ...state,
                ...{currentApp: action.payload}
            };
        case SET_DEPLOYMENT_CLOUD :
            return {
                ...state,
                ...{currentCloud: action.payload}
            };
        case UPDATE_INPUT_ERROR :
            return {
                ...state,
                ...{errorsInputs: {...state.errorsInputs, ...action.payload}}
            };
        case SET_DEPLOYMENT_MESSAGES :
            return {
                ...state,
                ...{messages: action.payload}
            };
        case SET_DEPLOYMENT_RESPONSE_MESSAGES :
            return {
                ...state,
                ...{messagesResponse: action.payload}
            };
        case UPDATE_FEATURES_DATA :
            return {
                ...state,
                ...{featuresData: action.payload}
            };
        case UPDATE_REQUIREMENTS :
            return {
                ...state,
                ...{
                    requirements: {...state.requirements, ...action.payload}
                }
            };
        case UPDATE_REQUEST_LOADING :
            return {...state, ...{requestLoading: !!action.payload}};
        case SWITCH_DEBUG_OPTION :

            let newDebug = {...state.debug};

            Object.keys(action.payload).map(key => {
                newDebug = {
                    ...newDebug,
                    ...{[key]: action.payload[key]}
                };
            })

            return {
                ...state,
                ...{debug: newDebug}
            };
        case WIPE_DEPLOYMENT_DATA :
            return initialState;
        case UPDATE_BODY_STACK :
            return {
                ...state,
                ...{bodyStack: {...state.bodyStack, ...action.payload}}
            };
        case UPDATE_INSTANCE_DATA :
            return {
                ...state,
                ...{instanceData: {...state.instanceData, ...action.payload}}
            };
        case WIPE_INSTANCE_DATA:
            return {
                ...state,
                ...{instanceData: initialState.instanceData}
            };
        case UPDATE_BODY_BUILDER:
            return {
                ...state,
                ...{bodyBuilder: {...state.bodyBuilder, ...action.payload}}
            };
        case WIPE_BODY_BUILDER:
            return {
                ...state,
                ...{bodyBuilder: DEFAULTS_BUILDER}
            };
        case UPDATE_BODY_SERVER:
            return {
                ...state,
                ...{bodyServer: {...state.bodyServer, ...action.payload}}
            };
        case WIPE_BODY_SERVER:
            return {
                ...state,
                ...{bodyServer: DEFAULTS_SERVER}
            };
        case UPDATE_BODY_CLOUD:
            return {
                ...state,
                ...{bodyCloud: {...state.bodyCloud, ...action.payload}}
            };
        case WIPE_BODY_CLOUD:
            return {
                ...state,
                ...{bodyCloud: DEFAULTS_CLOUD}
            };
        default:
            return state
    }
};

export default deploymentReducer
export const
    SET_APPS = 'SET_APPS',
    SET_INFO = 'SET_INFO',
    SET_PLANS = 'SET_PLANS',
    SET_SUBSCRIPTIONS = 'SET_SUBSCRIPTIONS',
    SET_PAYMENT_DATA = 'SET_PAYMENT_DATA'
    // UPDATE_BLOG_ARTICLES = 'UPDATE_BLOG_ARTICLES',
    // UPDATE_BLOG_FETCH_STATUS = 'UPDATE_BLOG_FETCH_STATUS',
    // UPDATE_DOCS_ARTICLES = 'UPDATE_DOCS_ARTICLES',
    // UPDATE_DOCS_FETCH_STATUS = 'UPDATE_DOCS_FETCH_STATUS',
;

export function setApps(data) {
    return {
        type: SET_APPS,
        payload: data
    }
}

export function setInfo(data) {
    return {
        type: SET_INFO,
        payload: data
    }
}

export function setPaymentData(data) {
    return {
        type: SET_PAYMENT_DATA,
        payload: data
    }
}

export function setPlans(data) {
    return {
        type: SET_PLANS,
        payload: data
    }
}

export function setSubscriptions(data) {
    return {
        type: SET_SUBSCRIPTIONS,
        payload: data
    }
}

// export function updateBlogArticles(articles) {
//     return {
//         type : UPDATE_BLOG_ARTICLES,
//         payload : articles
//     }
// }
//
// export function updateBlogFetchStatus(statusString) {
//     return {
//         type : UPDATE_BLOG_FETCH_STATUS,
//         payload : statusString
//     }
// }
//
// export function updateDocsArticles(articles) {
//     return {
//         type : UPDATE_DOCS_ARTICLES,
//         payload : articles
//     }
// }
//
// export function updateDocsFetchStatus(statusString) {
//     return {
//         type : UPDATE_DOCS_FETCH_STATUS,
//         payload : statusString
//     }
// }


import React from 'react'
import { connect } from 'react-redux'
import NotificationItem from './NotificationItem';
import PropTypes from 'prop-types';
import styles from './NotificationCenter.module.scss'

class NotificationCenter extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            queue: [],
            activeNotification: null,
            notificationIsHiding: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        let {notifications} = this.props;
        let {queue, activeNotification} = this.state;

        let newNotificationPassed = notifications.length !== prevProps.notifications.length;
        let newNotificationInQueue = queue.length !== prevState.queue.length;
        let newActiveNotification = activeNotification !== prevState.activeNotification && activeNotification;
        let activeNotificationRemoved = activeNotification !== prevState.activeNotification && !activeNotification;

        if (newNotificationPassed) this.addNotificationsToQueue(prevProps.notifications);
        if (newNotificationInQueue || activeNotificationRemoved) this.setNewActiveNotification();
        if (newActiveNotification) this.deactivateActiveNotification();
    }

    notificationTimeout = null;
    hideAnimationTimeout = null;

    addNotificationsToQueue = prevNotifications => {

        let {queue} = this.state;
        // DO NOT EVER exclude new stored notifications from this.state - the logic is based on indexes.
        // Instead, use 'status' key to manipulate items
        this.props.notifications.map((notification, notificationIndex) => {
            // check for new notifications in store
            if (!prevNotifications[notificationIndex]) {

                let ignoredMessage = queue.some(msg => {

                    let {status} = msg;
                    let queueItem = msg['notification'];
                    let queueItemKeys = Object.keys(queueItem);
                    let notFinished = status === 'pending' || status === 'active';
                    let equalWithAnother = !queueItemKeys.some(key => queueItem[key] !== notification[key] && key !== 'time');

                    return notFinished && equalWithAnother
                });
                // set pending status for component to understand what the hell is happening here and what to do
                let newNotification = {
                    notification: notification,
                    status: 'pending'
                };
                // don't show notifications equal to already queued ones as well as equal to active
                if (ignoredMessage) newNotification.status = 'finished';
                // set a new queue array instead of mutating for proper work
                this.setState({
                    queue: [
                        ...queue,
                        ...[newNotification]
                    ]
                })
            }
        })
    };

    setNewActiveNotification = () => {

        let {queue} = this.state;
        let noActiveNotifications = !queue.find(notification => notification.status === 'active');
        let pendingNotificationExists = queue.some(notification => notification.status === 'pending');

        if (noActiveNotifications && pendingNotificationExists) {

            let newActiveIndex = queue.findIndex(notification => notification.status === 'pending');
            let updatedQueue = [...queue]; // avoid mutation, but avoid JSON parse || stringify because of passed functions
            // setting new object allows to avoid mutation for the correct change of status
            updatedQueue[newActiveIndex] = {
                ...updatedQueue[newActiveIndex],
                ...{status: 'active'}
            };

            this.setState({
                activeNotification: queue[newActiveIndex]['notification'],
                queue: updatedQueue
            })
        }
        // hiding animation should be disabled every time function get called
        this.setState({notificationIsHiding: false})
    };

    deactivateActiveNotification = immediately => {

        let {activeNotification} = this.state;
        let {autoDismiss} = activeNotification;
        let removeTimeout = activeNotification && autoDismiss ? autoDismiss * 1000 : 6000;
        let hideAnimationLength = 150;
        // "immediately = true" allows to close notification avoiding timers, i.e. right now
        if (immediately) removeTimeout = hideAnimationLength;

        clearTimeout(this.notificationTimeout);
        clearTimeout(this.hideAnimationTimeout);

        this.notificationTimeout = setTimeout(
            () => {
                let {queue} = this.state;
                let currentActiveIndex = queue.findIndex(notification => notification.status === 'active');
                let updatedQueue = [...queue];
                // setting new object allows to avoid mutation for the correct change of status
                updatedQueue[currentActiveIndex] = {
                    ...updatedQueue[currentActiveIndex],
                    ...{status: 'finished'}
                };

                this.setState({
                    activeNotification: null,
                    queue: updatedQueue
                })
            },
            removeTimeout
        );
        // hideAnimationTimeout exists for smooth disappearing animation
        this.hideAnimationTimeout = setTimeout(
            () => {this.setState({notificationIsHiding: true})},
            removeTimeout - hideAnimationLength
        )
    };

    stopImmediately = () => {this.deactivateActiveNotification(true)};

    clearTimers = () => {
        clearTimeout(this.notificationTimeout);
        clearTimeout(this.hideAnimationTimeout);
    };

    render() {

        let {activeNotification, notificationIsHiding} = this.state;

        return (
            <div className={styles['notification-center']} ref={el => this.notificationCenter = el}>
                <NotificationItem
                    notification={activeNotification}
                    hide={notificationIsHiding}
                    stop={this.stopImmediately}
                    resetTimers={this.deactivateActiveNotification}
                    clearTimers={this.clearTimers}
                    notificationCenter={this.notificationCenter}
                />
            </div>
        );
    }
}

let mapStateToProps = store => {
    return {
        notifications: store.notifications
    }
};

export default connect(mapStateToProps)(NotificationCenter)

NotificationCenter.propTypes = {
    notifications: PropTypes.array
};
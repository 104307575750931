import {createStore, applyMiddleware} from 'redux';
import rootReducer from './reducers/_rootReducer';
import { createWrapper } from "next-redux-wrapper";
import { createLogger } from "redux-logger"
import * as config from '../config'


const logger = createLogger({});

function defineStore() {
    if (config.DEBUG) {
        return createStore(rootReducer, applyMiddleware(logger))
    } else {
        return createStore(rootReducer)
    }
}

export let store = defineStore();

// export default store;
const makeStore = () => store;
export const wrapper = createWrapper(makeStore);
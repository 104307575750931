let initialState = {
    layoutDimensions: {
        height: 0,
        width: 0,
    },
    scroll: {},
    theme : {},
    mobile: false,
};

import {
    UPDATE_THEME,
    UPDATE_LAYOUT_DIMENSIONS,
    UPDATE_UI_DATA,
} from '../actions/UIActions';

let UIReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_UI_DATA :
            return {...state, ...action.payload};
        case UPDATE_LAYOUT_DIMENSIONS :
            return {
                ...state,
                ...{
                    layoutDimensions: {
                        height: action.payload.height,
                        width: action.payload.width,
                    }
                }
            };
        case UPDATE_THEME :
            return {
                ...state,
                ...{
                    theme: {...state.theme, ...action.payload}
                }
            };
        default:
            return state
    }
};

export default UIReducer
import { ENV_LOCAL } from "../../config";
// log the page view with their URL
export const googlePageView = (url) => {
    if (window && window.gtag && !ENV_LOCAL) {
        const title = document.querySelector('title')?.textContent;
        let page = {
            page_path: url,
            page_title: title ? title : 'Undefined title'
        };
        window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, page)
    }
}
// log specific events happening.
export const googleEvent = ({ action, params }) => {
    if (window && window.gtag && !ENV_LOCAL) window.gtag('event', action, params)
}

export const CONSENT_HANDLED = 'consent_handled';
export const CONSENT_TYPES = [
    {
        code: 'analytics_storage',
        title: 'Analytics Storage',
        defaults: 'granted',
        description: 'Allow us to collect and store information about your visit, such as the pages you view and how you navigate our site. This data helps us to understand how our site is used and to improve your user experience.'
    },
    {
        code: 'ad_storage',
        title: 'Ad Storage',
        defaults: 'denied',
        // folded: true,
        description: 'Allow us to store information related to your interactions with ads on our site. This helps us to measure ad performance and ensure that you receive relevant ads based on your browsing habits.'
    }, {
        code: 'ad_user_data',
        title: 'Ad User Data',
        defaults: 'denied',
        // folded: true,
        description: 'Permit us to collect and use data about your interactions with ads, such as clicks and views. This data is used to improve the relevance and effectiveness of the ads you see.'
    }, {
        code: 'ad_personalization',
        title: 'Ad Personalization',
        defaults: 'denied',
        // folded: true,
        description: 'Enable us to personalize the ads you see based on your interests and browsing history. This helps us to provide you with ads that are more relevant to your preferences.'
    }
]
import React from 'react'
import styles from './DevLogs.module.scss'
import RippleLayout from "../../../../../components/ripple/RippleLayout";

class LogItem extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isActive: false,
        }
    }

    fillNotificationContent = (title, value, valueColor) => {
        let color = valueColor ? {color: valueColor} : {};
        return (
            <div className={styles['dev-logs__item__data__container']} style={color}>
                <div className={styles['dev-logs__item__data__container__title']}>{title} :</div>
                <div className={styles['dev-logs__item__data__container__value']}>{value ? value : ' - '}</div>
            </div>
        )
    };

    renderNotification = (mainColor) => {
        let {message, status, statusInfo, url, type, time, autoDismiss, errorFetch} = this.props.notification;
        return (
            <div className={styles['dev-logs__item__data']}>
                {this.fillNotificationContent('message', message)}
                {this.fillNotificationContent('status', errorFetch?.error?.status || status)}
                {this.fillNotificationContent('statusInfo', errorFetch?.error?.statusText || statusInfo)}
                {this.fillNotificationContent('url', errorFetch?.error?.url || url)}
                {this.fillNotificationContent('type', type, mainColor)}
                {this.fillNotificationContent('autoDismiss', autoDismiss)}
            </div>
        )
    }

    renderErrors = () => {
        let {messages} = this.props.error;
        return (
            <div className={styles['dev-logs__item__data']}>
                {
                    messages.map((item, index) => {
                        return (
                            <div
                                className={styles['dev-logs__item__data__container']}
                                style={{
                                    '--dev-logs-error-color': item.type === 'error'
                                        ? 'var(--colorFontError)'
                                        : 'var(--colorFontWarning)'
                                }}
                                key={index}
                            >
                                <div className={styles['dev-logs__item__data__container__icon']}>
                                    <i className='fas fa-exclamation-triangle'/>
                                </div>
                                <div className={styles['dev-logs__item__data__container__message']}>{item.message}</div>
                            </div>
                            )
                    })
                }
            </div>
        )
    }

    render() {

        let {error, notification} = this.props;
        let type = error?.type || notification?.type;
        let {isActive} = this.state;
        let state = isActive ? ` ${styles['dev-logs__item--unfold']}` : '';
        let mainColor = '';

        if (type === 'error') mainColor = 'var(--colorFontError)';
        if (type === 'success') mainColor = 'var(--colorFontSuccess)';
        if (type === 'warning') mainColor = 'var(--colorFontWarning)';
        if (type === 'invalid') mainColor = 'var(--colorFontLight)';
        let variableToCSS = {'--dev-logs-color': mainColor};

        return (
            <div className={`${styles['dev-logs__item']}${state}`} style={variableToCSS}>
                <RippleLayout
                    className={styles['dev-logs__item__minimal']}
                    onClick={() => this.setState({isActive: !this.state.isActive})}
                    visuals={{borderRadius: '0', colorBasic: ['var(--colorFontLight)']}}
                >
                    <i
                        className={`fas fa-chevron-right ${styles['dev-logs__item__minimal__state']}`}
                        aria-hidden="true"
                    />
                    <p className={styles['dev-logs__item__minimal__message']}>{notification?.message || error?.title}</p>
                    {!!notification && <p className={styles['dev-logs__item__minimal__time']}>{notification.time}</p>}
                    {
                        !!error &&
                        <div className={styles['dev-logs__item__minimal__time']}>
                            <i
                                className='fas fa-exclamation-triangle'
                                style={{
                                    color: error.messages.some(m => m.type === 'error')
                                        ? 'var(--colorFontError)'
                                        : 'var(--colorFontWarning)'
                                }}
                            />
                        </div>
                    }
                </RippleLayout>
                {!!notification && this.renderNotification(mainColor)}
                {!!error && this.renderErrors()}
            </div>
        );
    }
}

export default class DevLogs extends React.Component {
    renderLogs = () => {
        if (!!this.props.notifications?.length) {
            return [...this.props.notifications].reverse().map((notification, index) => {
                return <LogItem notification={notification} key={index}/>
            })
        } else if (!!this.props.errors?.length) {

            let handledErrors = [];

            this.props.errors.map(error => {
                if (!handledErrors.some(e => e.title === error.title)) {

                    let categoryErrors = [
                        ...this.props.errors.filter(e => e.title === error.title && e.type === 'error'),
                        ...this.props.errors.filter(e => e.title === error.title && e.type === 'warning'),
                    ];
                    let messages = [];

                    categoryErrors.map(e => {
                        e.messages.map(m => messages.push({message: m, type: error.type}))
                    })

                    handledErrors.push({title: error.title, messages: messages})
                }
            })

            return handledErrors.map((error, index) => {
                return <LogItem error={error} key={index}/>
            })
        }
    };

    render() {
        return <div className='dev-logs'>{this.renderLogs()}</div>
    }
}
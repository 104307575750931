export const
    SET_DEPLOYMENT_STACKS = 'SET_DEPLOYMENT_STACKS',
    SET_DEPLOYMENT_APPS = 'SET_DEPLOYMENT_APPS',
    SET_DEPLOYMENT_APP = 'SET_DEPLOYMENT_APP',
    SET_DEPLOYMENT_CLOUD = 'SET_DEPLOYMENT_CLOUD',
    UPDATE_FEATURES_DATA = 'UPDATE_FEATURES_DATA',
    UPDATE_REQUIREMENTS = 'UPDATE_REQUIREMENTS',
    UPDATE_REQUEST_LOADING = 'UPDATE_REQUEST_LOADING',
    SWITCH_DEBUG_OPTION = 'SWITCH_DEBUG_OPTION',
    WIPE_DEPLOYMENT_DATA = 'WIPE_DEPLOYMENT_DATA',
    //
    UPDATE_INSTANCE_DATA = 'UPDATE_INSTANCE_DATA',
    WIPE_INSTANCE_DATA = 'WIPE_INSTANCE_DATA',
    // request data,
    UPDATE_BODY_STACK = 'UPDATE_BODY_STACK',
    UPDATE_BODY_BUILDER = 'UPDATE_BODY_BUILDER',
    WIPE_BODY_BUILDER = 'WIPE_BODY_BUILDER',
    UPDATE_BODY_SERVER = 'UPDATE_BODY_SERVER',
    WIPE_BODY_SERVER = 'WIPE_BODY_SERVER',
    UPDATE_BODY_CLOUD = 'UPDATE_BODY_CLOUD',
    WIPE_BODY_CLOUD = 'WIPE_BODY_CLOUD',
    //
    UPDATE_INPUT_ERROR = 'UPDATE_INPUT_ERROR',
    SET_DEPLOYMENT_MESSAGES = 'SET_DEPLOYMENT_MESSAGES',
    SET_DEPLOYMENT_RESPONSE_MESSAGES = 'SET_DEPLOYMENT_RESPONSE_MESSAGES'
;

export function setDeploymentStacks(data) {
    return {
        type: SET_DEPLOYMENT_STACKS,
        payload: data
    }
}

export function setDeploymentApps(data) {
    return {
        type: SET_DEPLOYMENT_APPS,
        payload: data
    }
}

export function setDeploymentApp(data) {
    return {
        type: SET_DEPLOYMENT_APP,
        payload: data
    }
}

export function setDeploymentCloud(data) {
    return {
        type: SET_DEPLOYMENT_CLOUD,
        payload: data
    }
}

export function updateFeaturesData(data) {
    return {
        type: UPDATE_FEATURES_DATA,
        payload: data
    }
}

export function updateRequirements(data) {
    return {
        type: UPDATE_REQUIREMENTS,
        payload: data
    }
}

export function updateDeployRequestLoading(data) {
    return {
        type: UPDATE_REQUEST_LOADING,
        payload: data
    }
}

export function switchDebugOption(data) {
    return {
        type: SWITCH_DEBUG_OPTION,
        payload: data
    }
}

export function wipeDeploymentData(data) {
    return {
        type: WIPE_DEPLOYMENT_DATA,
        payload: data
    }
}

export function updateInstanceData(data) {
    return {
        type: UPDATE_INSTANCE_DATA,
        payload: data
    }
}

export function wipeInstanceData(data) {
    return {
        type: WIPE_INSTANCE_DATA,
        payload: data
    }
}

export function updateBodyStack(data) {
    return {
        type: UPDATE_BODY_STACK,
        payload: data
    }
}

export function updateBodyBuilder(data) {
    return {
        type: UPDATE_BODY_BUILDER,
        payload: data
    }
}

export function wipeBodyBuilder(data) {
    return {
        type: WIPE_BODY_BUILDER,
        payload: data
    }
}

export function updateBodyServer(data) {
    return {
        type: UPDATE_BODY_SERVER,
        payload: data
    }
}

export function wipeBodyServer(data) {
    return {
        type: WIPE_BODY_SERVER,
        payload: data
    }
}

export function updateBodyCloud(data) {
    return {
        type: UPDATE_BODY_CLOUD,
        payload: data
    }
}

export function wipeBodyCloud(data) {
    return {
        type: WIPE_BODY_CLOUD,
        payload: data
    }
}

export function updateInputError(data) {
    return {
        type: UPDATE_INPUT_ERROR,
        payload: data
    }
}

export function setDeploymentMessages(data) {
    return {
        type: SET_DEPLOYMENT_MESSAGES,
        payload: data
    }
}

export function setDeploymentResponseMessages(data) {
    return {
        type: SET_DEPLOYMENT_RESPONSE_MESSAGES,
        payload: data
    }
}


export const
    UPDATE_UI_DATA = 'UPDATE_UI_DATA',
    UPDATE_THEME = 'UPDATE_THEME',
    UPDATE_LAYOUT_DIMENSIONS = 'UPDATE_LAYOUT_DIMENSIONS'
;

export function updateUiData(data) {
    return {
        type : UPDATE_UI_DATA,
        payload : data
    }
}

export function updateLayoutDimensions(data) {
    return {
        type : UPDATE_LAYOUT_DIMENSIONS,
        payload : data
    }
}

export function updateTheme(data) {
    return {
        type : UPDATE_THEME,
        payload : data
    }
}
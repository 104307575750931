import React from "react";
import styles from './SalesContacts.module.scss';
import mobileNavStyles from '../mobile/MobileHeader.module.scss';
import { SOCIAL_DATA } from "../../../../../settings/socialProviders";
import Button from "../../../../../components/ripple/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function SalesContacts({isMobile}) {

    const share = ['WhatsApp', 'Telegram'];

    const renderLink = ({
                            classNameDesktop,
                            margin,
                            colors,
                            name,
                            linkName,
                            link,
                            icon,
                            key,
                        }) => {
        if (isMobile) {
            return (
                <a className={styles.linkMobile} href={link} key={key}>
                    <Button
                        title={name}
                        className={`${mobileNavStyles.nav__container__links__link__button} ${styles.linkMobile__button}`}
                        iconLeft={icon}
                        importedIcon
                        displayType='text'
                        visuals={{
                            colorBasic: [colors[0], colors?.[1] || colors[0]],
                            colorFontNotContained: [colors[0], colors?.[1] || colors[0]],
                            colorFont: [colors[0], colors?.[1] || colors[0]],
                            animationDuration: '300ms',
                            borderRadius: '0',
                            height: '5.5rem',
                        }}
                    />
                </a>
            )
        } else {
            return (
                <a
                    style={{
                        '--social-margin': margin,
                        '--social-color': colors[0],
                        '--social-color-dark': colors?.[1],
                    }}
                    className={`${styles['link']}${classNameDesktop ? ' ' + classNameDesktop : ''}`}
                    href={link}
                    target="_blank"
                    aria-label={`Contact us via ${name}`}
                    rel='nofollow'
                    draggable='false'
                    key={key}
                >
                    {linkName && <span className={styles['link__text']}>{linkName}</span>}
                    {/*<i className={`${icon} ${styles['link__logo']}`} aria-hidden="true"/>*/}
                    <FontAwesomeIcon className={`${styles['link__logo']}`} icon={icon}/>
                </a>
            )
        }
    }

    return (
        <div className={styles['socials']}>
            {isMobile && <h3 className={styles['socials__title']}>Contact Sales</h3>}
            <div className={styles.socials__links}>
                {renderLink({
                    classNameDesktop: styles['link--phone'],
                    colors: ['var(--colorFontInfo)'],
                    name: '+(373) 60 775 719',
                    linkName: '+(373) 60 775 719',
                    link: 'tel:+37360775719',
                    icon: 'fas fa-mobile-alt',
                })}
                {
                    SOCIAL_DATA.map((item, index) => {
                        if (share.includes(item.name)) return (

                            renderLink({
                                margin: item.margin,
                                colors: item.color,
                                name: item.name,
                                link: item.linkContact,
                                icon: item.icon,
                                key: index
                            })
                        )
                    })
                }
            </div>
        </div>
    )
}
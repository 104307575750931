export const
    ADD_ERROR = 'ADD_ERROR',
    WIPE_ERRORS_LOG = 'WIPE_ERRORS_LOG'
;

export function addErrorToLog(data) {
    return {
        type : ADD_ERROR,
        payload : data
    }
}

export function wipeErrorsLog() {
    return {
        type : WIPE_ERRORS_LOG,
    }
}
import React from "react";
import { connect } from "react-redux";
import { addErrorToLog, wipeErrorsLog } from "../../../redux/actions/ErrorsLogActions";
import { DOMAIN_BASE } from "../../../config";
import { DESCRIPTION_DEFAULT } from "../../../helpers/templates";
import { withRouter } from "next/router";

class SeoDebugger extends React.Component {

    constructor(props) {
        super(props);

        this.state = {}
    }

    debugCommon = () => {

        const title = document.querySelector('title')?.textContent;
        const description = document.querySelector('meta[name="description"]')?.content;
        const robotsList = Array.from(document.querySelectorAll('meta[name="robots"]'));
        // imitate noindex on dev as it must be on prod
        const noindex = robotsList?.some(tag => tag.content.includes('noindex') && !tag.content.includes('dev'));

        let titleWarnings = [];
        let titleErrors = [];

        switch (true) {
            case !title:
                titleErrors.push('No <title> content found');
                break;
            case title.length < 20:
                titleErrors.push(`<title> content text should have at least 20 chars length (on development). Currently "${title}" is ${title.length} char(s) length`);
                break;
            // case (title.length < 68 || title.length > 88):
            //     titleWarnings.push(`<title> content ideal length is between 68-88 chars (on development). Currently "${title}" is ${title.length} char(s) length. As known for now - do not affect SEO.`);
            //     break;
        }

        if (titleErrors.length) {
            this.props.dispatch(addErrorToLog({
                title: 'SEO title',
                type: 'error',
                messages: titleErrors,
            }))
        }
        if (titleWarnings.length) {
            this.props.dispatch(addErrorToLog({
                title: 'SEO title',
                type: 'warning',
                messages: titleWarnings,
            }))
        }

        if (!!noindex) return;

        let descriptionWarnings = [];
        let descriptionErrors = [];

        switch (true) {
            case !description:
                descriptionErrors.push('No <meta name="description"> content found on page that is not excluded from indexing');
                break;
            case description.length < 50:
                descriptionErrors.push(`<meta name="description"> content is too short (${description.length} char(s)) - min length is 50 chars). Google may ignore it and choose some wrong page content for description.`);
                break;
            case description.length > 300:
                descriptionErrors.push(`<meta name="description"> content is too long (${description.length} char(s)) - max length is 300 chars). Google may ignore it and choose some wrong page content for description.`);
                break;
            case (description.length < 155 || description.length > 165):
                descriptionWarnings.push(`<meta name="description"> content recommended length is between 155-165 chars. Currently it's ${description.length} char(s) length`);
                break;
        }

        if (descriptionWarnings.length) {
            this.props.dispatch(addErrorToLog({
                title: 'SEO description',
                type: 'warning',
                messages: descriptionWarnings,
            }))
        }
        if (descriptionErrors.length) {
            this.props.dispatch(addErrorToLog({
                title: 'SEO description',
                type: 'error',
                messages: descriptionErrors,
            }))
        }
    }

    debugOpenGraph = () => {

        const ogTitle = document.querySelector('meta[property="og:title"]')?.content;
        const ogDescription = document.querySelector('meta[property="og:description"]')?.content;

        let ogWarnings = [];
        let ogErrors = [];

        switch (true) {
            case !ogTitle:
                ogErrors.push('No <meta property="og:title"> content found. Link title should give user some basic information about where he is going to appear.');
                break;
            case ogTitle === DOMAIN_BASE:
                ogWarnings.push(`<meta property="og:title"> content is default. Link title should give user some basic information about where he is going to appear.`);
                break;
        }

        switch (true) {
            case !ogDescription:
                ogWarnings.push('No <meta property="og:description"> content found. Description extends user knowledge about where he is going to appear.');
                break;
            case ogDescription === DESCRIPTION_DEFAULT:
                ogWarnings.push(`<meta property="og:description"> content is default. Description extends user knowledge about where he is going to appear.`);
                break;
        }

        if (ogWarnings.length) {
            this.props.dispatch(addErrorToLog({
                title: 'OpenGraph (social link)',
                type: 'warning',
                messages: ogWarnings,
            }))
        }
        if (ogErrors.length) {
            this.props.dispatch(addErrorToLog({
                title: 'OpenGraph (social link)',
                type: 'error',
                messages: ogErrors,
            }))
        }
    }

    // debugJSONLD = () => {
    //     const contentJSONLD = document.getElementById('content-jsonld');
    //     const robotsList = Array.from(document.querySelectorAll('meta[name="robots"]'));
    //     // imitate noindex on dev as it must be on prod
    //     const noindex = robotsList?.some(tag => tag.content.includes('noindex') && !tag.content.includes('dev'));
    //
    //     if (!noindex && !contentJSONLD) {
    //         this.props.dispatch(addErrorToLog({
    //             title: 'JSON-LD content',
    //             type: 'warning',
    //             messages: ['No json+ld content found. Robots will try to analyse content in automatic mode (HTML)'],
    //         }))
    //     }
    // }

    initiateDebug = () => {
        this.debugCommon();
        this.debugOpenGraph();
        // this.debugJSONLD();
    }

    componentDidMount() {
        setTimeout(this.initiateDebug, 1000);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {router} = this.props;
        if (router.asPath !== prevProps.router.asPath) {
            this.props.dispatch(wipeErrorsLog());
            setTimeout(this.initiateDebug, 1000);
        }
    }

    render() {
        return null
    }
}

let mapStateToProps = state => ({
    errorsLog: state.errorsLog,
})

export default connect(mapStateToProps)(withRouter(SeoDebugger))
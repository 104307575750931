import React from 'react'
import { connect } from 'react-redux'
import DevLogs from './components/DevLogs';
import styles from './DevControls.module.scss'
import Button from "../../../../components/ripple/Button";

class DevControls extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            active: null,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let {active} = this.state;
        if (active !== prevState.active) {
            if (active !== null) document.addEventListener('click', this.handleClickOutsideDevPanel);
            if (!active) document.removeEventListener('click', this.handleClickOutsideDevPanel);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutsideDevPanel);
    }

    handleClickOutsideDevPanel = e => {

        let notDevPanel = !this.errorsContent?.contains(e.target) && !this.notificationsContent?.contains(e.target);
        let containsSwitchButton = !!this.errors?.contains(e.target) || !!this.notifications?.contains(e.target);

        if (notDevPanel && !containsSwitchButton) this.setState({active: null})
    };

    renderControls = () => {

        let {active} = this.state;
        let {errorsLog, notifications} = this.props;
        let controls = [
            {
                prop: 'errors',
                icon: 'exclamation-triangle',
                condition: !!errorsLog?.length,
                color: errorsLog.some(e => e.type === 'error') ? ['var(--colorFontError)'] : ['var(--colorFontWarning)'],
                content: <DevLogs errors={errorsLog}/>
            }, {
                prop: 'notifications',
                icon: 'bell',
                color: notifications.some(e => e.type === 'error')
                    ? ['var(--colorFontError)']
                    : notifications.some(e => e.type === 'warning')
                        ? ['var(--colorFontWarning)'] : ['var(--colorFontInfo)'],
                content: <DevLogs notifications={notifications}/>
            },
        ];

        return controls.map((control, index) => {

            let {prop, icon, content, color, condition} = control;
            let contentExists = !!condition ? condition : !!this.props[prop]?.length;
            if (!contentExists) return null;
            let isActive = active === prop;
            let activeContent = isActive ? ` ${styles['dev-controls__item__content--show']}` : '';

            return (
                <div className={styles['dev-controls__item']} key={index}>
                    <Button
                        className={styles['dev-controls__item__trigger']}
                        icon={'fas fa-' + icon}
                        displayType={isActive ? 'contained' : 'text'}
                        onClick={() => this.setState({active: isActive ? null : prop})}
                        visuals={{
                            borderRadius: '50%',
                            height: '4rem',
                            colorBasic: color ? color : ['var(--colorFontWarning)']
                        }}
                        passedRef={el => this[prop] = el}
                    />
                    <div
                        className={`${styles['dev-controls__item__content']}${activeContent}`}
                        ref={el => this[prop + 'Content'] = el}
                    >
                        {content}
                    </div>
                </div>
            )
        })
    }

    render() {
        return (
            <div className={styles['dev-controls']}>
                {this.renderControls()}
            </div>
        )
    }

}

let mapStateToProps = store => {
    return {
        notifications: store.notifications,
        errorsLog: store.errorsLog,
    }
};

export default connect(mapStateToProps)(DevControls)
import React from "react";
import { connect } from 'react-redux'
import styles from './UserMenu.module.scss'
import Link from "next/link";
import {
    NAV_DASHBOARD,
    NAV_LOGOUT,
    NAV_PRICING,
    NAV_PROFILE,
    NAV_SIGN_IN,
    NAV_SIGN_UP,
    USER_ACCESS_TOKEN_NAME
} from "../../../../../config";
import Button from "../../../../../components/ripple/Button";
import { updateTheme } from "../../../../../redux/actions/UIActions";
import RippleLayout from "../../../../../components/ripple/RippleLayout";
import { useRouter } from "next/router";
import { getCookie } from "../../../../../helpers/common";
import Loading from "../../../../../components/Loading";
import {
    DARK_THEME_INDEX,
    DARK_THEME_NAME,
    LIGHT_THEME_INDEX,
    LIGHT_THEME_NAME,
    SYSTEM_THEME_INDEX, SYSTEM_THEME_NAME
} from "../../../../../helpers/ui/ui";

function UserMenu({user, reveal, onNavClick, dispatch, theme}) {

    const router = useRouter();
    const loading = !!getCookie(USER_ACCESS_TOKEN_NAME) && !Object.keys(user || {})?.length && reveal;
    const isAuth = !!Object.keys(user || {})?.length || loading;

    const renderButton = ({link, title, icon, onClick}, inner) => {

        const base = (
            <Button
                title={title}
                className={inner ? styles['link-contained__button'] : styles['link__button']}
                iconLeft={icon}
                importedIcon
                displayType='text'
                onClick={onClick ? onClick : onNavClick}
                visuals={{
                    colorBasic: ['var(--colorFontInfo)'],
                    colorFontNotContained: ['var(--colorFontTitle)'],
                    animationDuration: '300ms',
                    borderRadius: '0',
                    height: '5rem',
                }}
                aria-label={title}
                draggable='false'
            />
        )

        return onClick
            ? base
            :
            <Link
                prefetch={false}
                href={link}
                className={inner ? styles['link-contained'] : styles['link']}
                draggable='false'
            >
                {base}
            </Link>;
    };

    const showInfo = () => {
        return (
            <>
                <div className={styles['user__info__photo']}>
                    <img
                        src={user.photo + '?s=52'} alt='User photo'
                        className={styles['user__info__photo__image']}
                    />
                </div>
                <div className={styles['user__info__container']}>
                    <p className={styles['user__info__container__name']}>{`${user.first_name} ${user.last_name}`}</p>
                    <p className={styles['user__info__container__email']}>{user.email}</p>
                </div>
            </>
        )
    }

    const showLimits = () => {

        const limit = user.deployments_left;

        if (!limit && limit !== null && limit !== 0) return null;

        return (
            <RippleLayout
                className={styles.user__limits}
                onClick={() => {
                    router.push(NAV_PRICING);
                    onNavClick();
                }}
                visuals={{
                    colorBasic: ['var(--colorFontInfo)'],
                    colorFontNotContained: ['var(--colorFontTitle)'],
                    animationDuration: '300ms',
                }}
            >
                {
                    limit === null
                        ?
                        <>
                            <i className={`fas fa-infinity ${styles.user__limits__icon}`}/>
                            <p className={styles.user__limits__text}>Limitless deployments</p>
                        </>
                        :
                        <>
                            <p className={styles.user__limits__limit}>{limit}</p>
                            <p className={styles.user__limits__text}>Deployment(s) available</p>
                        </>
                }
            </RippleLayout>
        )
    }

    const showUser = () => {
        return (
            <>
                <div className={styles.user__info}>{showInfo()}</div>
                {showLimits()}
                <div className={styles.user__links}>
                    {renderButton({link: NAV_DASHBOARD, title: 'Dashboard', icon: 'fas fa-columns'}, true)}
                    {renderButton({link: NAV_PROFILE, title: 'Manage account', icon: 'fas fa-cog'}, true)}
                </div>
            </>
        )
    }

    const showGuest = () => {
            return (
                <div className={styles.user__sign}>
                    {renderButton({link: NAV_SIGN_IN, title: 'Sign in', icon: 'fas fa-sign-in-alt'}, true)}
                    {renderButton({link: NAV_SIGN_UP, title: 'Sign up', icon: 'fas fa-sign-out-alt'}, true)}
                </div>
            )
        }
    ;

    const themeSwitcher = () => {

        const {type} = theme;

        let icon = theme.dark ? 'fas fa-moon' : 'fas fa-sun';
        let title = SYSTEM_THEME_NAME;
        let next = LIGHT_THEME_INDEX;

        // if (type === SYSTEM_THEME_INDEX) {
        //     icon = theme.dark ? 'fas fa-moon' : 'fas fa-sun';
        //     title = SYSTEM_THEME_NAME;
        //     next = LIGHT_THEME_INDEX;
        // }

        if (type === LIGHT_THEME_INDEX) {
            icon = 'fas fa-sun';
            title = LIGHT_THEME_NAME;
            next = DARK_THEME_INDEX;
        }

        if (type === DARK_THEME_INDEX) {
            icon = 'fas fa-moon';
            title = DARK_THEME_NAME;
            next = SYSTEM_THEME_INDEX;
        }

        return renderButton({
            onClick: () => dispatch(updateTheme({...{type: next}})),
            title: title,
            icon: icon
        }, false);
    };

    return (
        <div className={styles.user}>
            {
                loading
                    ? <Loading
                        className={styles.user__loading}
                        type='component'
                        icon={{faCode: 'fas fa-chevron-down', animationType: 'bottom'}}
                    />
                    : !isAuth ? showGuest() : showUser()
            }
            {themeSwitcher()}
            {/*{ENV_LOCAL && renderButton({*/}
            {/*        onClick: () => {*/}
            {/*            dispatchNotification({*/}
            {/*                message: 'In work',*/}
            {/*                type: 'info',*/}
            {/*            })*/}
            {/*        },*/}
            {/*        title: 'Performance mode',*/}
            {/*        // title: 'Fancy mode',*/}
            {/*        icon: 'fas fa-tachometer-alt',*/}
            {/*        // icon: 'fas fa-stream',*/}
            {/*    },*/}
            {/*    false*/}
            {/*)}*/}
            {isAuth && renderButton({link: NAV_LOGOUT, title: 'Log out', icon: 'fas fa-sign-out-alt'}, false)}
        </div>
    )
}

const mapStateToProps = state => ({
    theme: state.UI.theme,
    user: state.userData.user,
});

export default connect(mapStateToProps)(UserMenu);
import { createFetchAsync } from "../../fetch/fetchRequestAsync";
import { DB_LOGIN, DB_PAGES } from "../../../config";
import { cliColor, cliError } from "../../cli/colors";

export default async function getPages(pathname) {

    const login = await createFetchAsync({
        URL: DB_LOGIN,
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: {
            email: process.env.NEXT_PUBLIC_POSTGREST_EMAIL,
            pass: process.env.NEXT_PUBLIC_POSTGREST_PASSWORD
        },
    });

    if (login.status !== 200) return login.statusText;

    const page = await createFetchAsync({
        URL: DB_PAGES,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${login.data.token}`
        },
    });

    if (page.status !== 200) return page.statusText;

    if (!pathname) return page.data;

    const foundPage = page.data.find(page => page.url === pathname) || null;

    switch (true) {
        case !!foundPage && !foundPage.seo_title:
            cliError(`No seo title for ${pathname} found`)
            throw (`No seo title for ${pathname} found`);
        case !!foundPage:
            return foundPage;
        default:
            cliError(`No SEO page data found for ${pathname}`)
            throw (`No SEO page data found for ${pathname}`)
    }
    // if (!!foundPage) {
    //     return foundPage;
    // } else {
    //     throw ('No SEO page data found in database!')
    // }
}
import React from "react";
import PropTypes from 'prop-types';
import {
    buildStyleVariables,
    calculateRippleGeometry,
    MAX_RIPPLE_LAYOUT_RIPPLE_LAYERS,
    renderRippleLayers
} from "./_helpers";
import { store } from "../../redux/store";

class RippleLayout extends React.Component {

    isUnmounted = false;
    mouseDownTime = null;// time tracker to define if user is holding mouse
    timeoutClearDOM = null; // timeout to remove excessive DOM (ripple) elements

    constructor(props) {
        super(props);

        this.state = {
            visualVariables: {},
            config: {},
            rippleLayers: [],
            currentLayerId: 0,
            isHolding: false,
        };
    }

    defineConfig = () => {

        let {displayType = 'text'} = this.props;
        let baseClass = 'ripple-layout';
        let config = {
            baseClass: baseClass,
            displayType: !!displayType ? displayType : 'text',
        };

        this.setState({config: config})
    };

    defineVisuals = () => {

        let {visuals} = this.props;
        let unified = ['borderRadius', 'animationDuration', 'padding', 'height', 'width'];

        this.setState({visualVariables: buildStyleVariables(visuals, unified, 'rippleLayout')})
    };

    componentDidMount() {
        this.defineConfig();
        this.defineVisuals();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        let {visuals} = this.props;
        // define config in lifecycle to avoid excessive re-rendering
        if (this.props !== prevProps) this.defineConfig();
        if (visuals !== prevProps.visuals) this.defineVisuals();
    }

    // this event handler helps to define if user stopped holding mouse outside button (mouseUp can't define it by itself)
    mouseUpOutside = e => {
        if (!this.rippleLayout.contains(e.target)) this.mouseUp();
    };

    onMouseHold = () => {
        // define if user is holding mouse
        let {mouseDownTime} = this;
        if (!!mouseDownTime) {
            let {visuals} = this.props;
            let passedAnimationDuration = !!visuals && !!visuals.animationDuration ? parseFloat(visuals.animationDuration) : 500;
            let trackStartTime = Math.ceil(passedAnimationDuration / 1.67);
            setTimeout(this.onMouseHold, 1);
            if (performance.now() - mouseDownTime > trackStartTime) { // something in range of 3/5 animation duration
                if (!this.isUnmounted) this.setState({isHolding: true});
                document.addEventListener('mouseup', this.mouseUpOutside);
                document.addEventListener('touchend', this.mouseUpOutside);
            }
        }
    };

    mouseDown = e => {
        clearTimeout(this.timeoutClearDOM); // do not trigger DOM removal if user clicked
        let currentTime = performance.now();
        let {currentLayerId} = this.state;
        let rippleLayers = [...this.state.rippleLayers];
        let newLayer = {
            ...calculateRippleGeometry(e, this.rippleLayout),
            ...{key: currentTime} // is required for rerender even if user clicks multiple times in one position
        };
        // time track starts here
        this.mouseDownTime = currentTime;
        this.onMouseHold();
        // rewrite existing excessive layers to reduce performance issues
        rippleLayers = [
            ...rippleLayers.slice(0, currentLayerId),
            ...[newLayer],
            ...rippleLayers.slice(currentLayerId + 1)
        ]

        if (!this.isUnmounted) this.setState({rippleLayers: rippleLayers})
    };

    mouseUp = e => {
        // time track stops here
        this.mouseDownTime = null;
        let {currentLayerId, isHolding, rippleLayers} = this.state;
        let {visuals} = this.props;
        let passedAnimationDuration = !!visuals && !!visuals.animationDuration ? parseFloat(visuals.animationDuration) : 500;
        let newLayerId = null;
        // set index for next possible layer
        newLayerId = currentLayerId < MAX_RIPPLE_LAYOUT_RIPPLE_LAYERS - 1 ? currentLayerId + 1 : 0;
        // prevent performance issues
        document.removeEventListener('mouseup', this.mouseUpOutside);
        document.removeEventListener('touchend', this.mouseUpOutside);
        // clear DOM immediately if user was holding mouse.
        // this is required for hover animation start immediately after mouse release
        if (!!isHolding) {
            setTimeout(
                () => {
                    if (!this.isUnmounted) this.setState({rippleLayers: [], currentLayerId: 0})
                }, 0
            )
        }
        // start timer that will remove useless dom (ripple) elements
        this.timeoutClearDOM = setTimeout(
            () => {
                if (!this.isUnmounted && !!rippleLayers.length) this.setState({rippleLayers: [], currentLayerId: 0})
            },
            passedAnimationDuration
        );

        if (!this.isUnmounted) this.setState({
            isHolding: false, // reset state that shows if user is holding button
            currentLayerId: newLayerId,
        })
    }

    componentWillUnmount() {
        this.isUnmounted = true;
        this.mouseDownTime = null;
        clearTimeout(this.timeoutClearDOM);
        this.isUnmounted = true;
        document.removeEventListener('mouseup', this.mouseUpOutside);
        document.removeEventListener('touchend', this.mouseUpOutside);
    }

    render() {

        let {config, rippleLayers, visualVariables} = this.state;
        let {children, className, style, disabled, onClick, passedRef} = this.props;
        let passedClass = className ? ` ${className}` : '';
        let {baseClass, displayType} = config;
        let withRipple = !!rippleLayers && !!rippleLayers.length ? ` ${baseClass}--with-ripple` : '';
        let disabledClass = !!disabled ? ` ${baseClass}-container--disabled` : '';

        const {mobile} = store.getState().UI;

        return (
            <div
                className={`${baseClass}-container ${baseClass}-container--${displayType}${disabledClass}${passedClass}`}
                style={{...visualVariables, ...style}}
            >
                <div
                    className={`${baseClass} ${baseClass}--${displayType}${withRipple}`}
                    onMouseDown={(!!disabled || mobile) ? undefined : this.mouseDown}
                    onMouseUp={(!!disabled || mobile) ? undefined : this.mouseUp}
                    onTouchStart={(!!disabled || !mobile) ? undefined : this.mouseDown}
                    onTouchEnd={(!!disabled || !mobile) ? undefined : this.mouseUp}
                    onClick={!!disabled ? () => {} : onClick}
                    ref={button => {
                        this.rippleLayout = button;
                        if (passedRef) passedRef(button);
                    }}
                    tabIndex={this.props.tabIndex || '0'}
                >
                    {renderRippleLayers(this.state)}
                    {children}
                </div>
            </div>
        );
    }
}

export default RippleLayout

RippleLayout.propTypes = {
    displayType: PropTypes.oneOf(['outlined', 'text']),
    visuals: PropTypes.shape({
        // array props can be ['color1', 'color2'] for light and dark theme or ['color'] for both themes
        colorBasic: PropTypes.arrayOf(PropTypes.string),
        colorRippleTint: PropTypes.arrayOf(PropTypes.string),
        colorBorder: PropTypes.arrayOf(PropTypes.string),
        opacityHover: PropTypes.arrayOf(PropTypes.string),
        // unified for both themes
        borderRadius: PropTypes.string,
        animationDuration: PropTypes.string,
        padding: PropTypes.string,
        height: PropTypes.string,
        width: PropTypes.string,
    }),
    disabled: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
    passedRef: PropTypes.func,
    tabIndex: PropTypes.string,
}
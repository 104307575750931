let initialState = {
    apps: [],
    clouds: [],
    plans: [],
    subscriptions: [],
    appCategories: {
        categories: [],
        tagList: []
    },
    // blog: {
    //     status: 'loading',
    //     articles: null,
    // },
    // docs: {
    //     status: 'loading',
    //     articles: null,
    // },
};

import {
    SET_APPS,
    SET_INFO,
    SET_PLANS,
    SET_SUBSCRIPTIONS,
    SET_PAYMENT_DATA,
    // UPDATE_BLOG_ARTICLES,
    // UPDATE_BLOG_FETCH_STATUS,
    // UPDATE_DOCS_ARTICLES,
    // UPDATE_DOCS_FETCH_STATUS
} from '../actions/CommonDataActions'

let commonData = (state = initialState, action) => {
    switch (action.type) {
        case SET_APPS:
            return {
                ...state,
                ...{apps: action.payload}
            };
        case SET_INFO:
            return {...state, ...action.payload};
        case SET_PAYMENT_DATA:
            return {...state, ...{paymentInfo: action.payload}};
        case SET_PLANS:
            return {
                ...state,
                ...{plans: action.payload}
            };
        case SET_SUBSCRIPTIONS:
            return {
                ...state,
                ...{subscriptions: action.payload}
            };
        // case UPDATE_BLOG_ARTICLES :
        //     return {
        //         ...state,
        //         blog: {
        //             ...state.blog,
        //             ...{
        //                 articles: action.payload
        //             }
        //         }
        //     };
        // case UPDATE_BLOG_FETCH_STATUS :
        //     let blogStatus = action.payload;
        //     if (action.payload !== undefined && action.payload !== 'success' && action.payload !== 'loading' && action.payload !== 'error') {
        //         blogStatus = state.blog.status;
        //         console.error(`Invalid status "${action.payload}" sent to UPDATE_BLOG_FETCH_STATUS action. "blog.status" will remain unchanged`)
        //     }
        //     return {
        //         ...state,
        //         blog : {
        //             ...state.blog,
        //             ...{status: action.payload}
        //         }
        //     };
        // case UPDATE_DOCS_ARTICLES :
        //     return {
        //         ...state,
        //         docs: {
        //             ...state.docs,
        //             ...{
        //                 articles: action.payload
        //             }
        //         }
        //     };
        // case UPDATE_DOCS_FETCH_STATUS :
        //     let docsStatus = action.payload;
        //     if (action.payload !== undefined && action.payload !== 'success' && action.payload !== 'loading' && action.payload !== 'error') {
        //         docsStatus = state.docs.status;
        //         console.error(`Invalid status "${action.payload}" sent to UPDATE_DOCS_FETCH_STATUS action. "docs.status" will remain unchanged`)
        //     }
        //     return {
        //         ...state,
        //         docs : {
        //             ...state.docs,
        //             ...{status: action.payload}
        //         }
        //     };
        default:
            return state
    }
};

export default commonData
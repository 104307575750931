// DO NOT .gitignore THIS FILE !!!
// DO NOT .gitignore THIS FILE !!!
// DO NOT .gitignore THIS FILE !!!
// DO NOT .gitignore THIS FILE !!!
// DO NOT .gitignore THIS FILE !!!

let url_parser = { resolve: (str1, str2) => str1 + str2 };
export function withoutBaseURL(route){ // @todo - remove after all CORS issues is fixed with node.js/api
    // this function allow to pass CORS. See next.config.js - noCorsApi
    let baseURL = process.env.NEXT_PUBLIC_PROTOCOL + process.env.NEXT_PUBLIC_URL_BASE;
    let split = route.split(baseURL);
    if (split.length === 2) return split[1];
    return route;
}
//    ===    GLOBAL
export const DEBUG = process.env.NEXT_PUBLIC_DEBUG === 'true';
export const ENV_LOCAL = process.env.NEXT_PUBLIC_LOCAL_ENVIRONMENT === 'true';
export const NAME_PREFIX = !!DEBUG ? '[dev] ' : '';
export const NAME_PROJECT = 'TryDirect';
export const NAME_BASE = NAME_PREFIX + NAME_PROJECT;
export const DOMAIN_BASE = process.env.NEXT_PUBLIC_URL_BASE;
export const URL_BASE = process.env.NEXT_PUBLIC_PROTOCOL + DOMAIN_BASE;
export const POSTGREST_BASE = process.env.NEXT_PUBLIC_POSTGREST_PROTOCOL + process.env.NEXT_PUBLIC_POSTGREST_DOMAIN + ':' + process.env.NEXT_PUBLIC_POSTGREST_PORT;
//    ===    DATABASE
export const DB_LOGIN = POSTGREST_BASE + '/rpc/login';
export const DB_PAGES = POSTGREST_BASE + '/page';
//    ===    API
export const URL_SERVER_APP = URL_BASE + '/server/app'; 
export const URL_SERVER_USER = URL_BASE + '/server/user/';
//    ===    CDN CONFIG
export const CDN_ENABLED = process.env.NEXT_PUBLIC_CDN_ENABLED === 'true';
export const CDN_SUBDOMAIN = process.env.NEXT_PUBLIC_CDN_SUBDOMAIN;
//    ===    FILES
export const URL_PICTURES_BASE = process.env.NEXT_PUBLIC_URL_PICTURES_BASE;
export const URL_UPLOADED_PICTURES_FOLDER = '/files';
export const URL_PICTURES_FULL = URL_PICTURES_BASE + URL_UPLOADED_PICTURES_FOLDER;
//    ===    AUTH
export const HASH = Math.random().toString(36).substring(7);
export const URL_AUTH = URL_SERVER_USER + 'auth/';
export const USER_ACCESS_TOKEN_NAME = 'access_token';
export const URL_LOGIN = URL_AUTH + 'login';
export const URL_SIGN_UP = URL_AUTH + 'register';
export const URL_LOGOUT = !ENV_LOCAL ? URL_AUTH + 'logout' : withoutBaseURL(URL_AUTH + 'logout');
export const URL_EMAIL_CONFIRMATION_RESEND = URL_AUTH + 'confirmation/resend';
export const URL_EMAIL_CONFIRMATION_CHANGE = URL_AUTH + 'change_email';
export const URL_LOGIN_WITH = !ENV_LOCAL ? URL_SERVER_USER + 'oauth_client/login/' : withoutBaseURL(URL_SERVER_USER + 'oauth_client/login/');
export const URL_DEAUTHORIZE_WITH = url_parser.resolve(URL_SERVER_USER, 'oauth_client/deauthorized/');
export const LAST_STATE_STORAGE_NAME = 'last_state_storage';
//    ===    USER
export const URL_ME = !ENV_LOCAL ? URL_SERVER_USER + 'oauth_server/api/me' : withoutBaseURL(URL_SERVER_USER + 'oauth_server/api/me');
export const URL_EMAIL_CONFIRM = URL_SERVER_USER + 'auth/email/confirm';
export const URL_ACCOUNT_COMPLETE = !ENV_LOCAL ? URL_SERVER_USER + 'auth/account/complete' : withoutBaseURL(URL_SERVER_USER + 'auth/account/complete');
export const URL_PASSWORD_SET = !ENV_LOCAL ? URL_SERVER_USER + 'auth/password' : withoutBaseURL(URL_SERVER_USER + 'auth/password');
export const URL_PASSWORD_RECOVER = URL_SERVER_USER + 'auth/recover';
export const URL_PASSWORD_RESET = URL_SERVER_USER + 'auth/reset';
export const URL_GET_USER_IP = !ENV_LOCAL ? URL_SERVER_USER + 'auth/ip' : withoutBaseURL(URL_SERVER_USER + 'auth/ip');
export const URL_SUBSCRIPTIONS_GET = !ENV_LOCAL ? URL_SERVER_USER + 'subscriptions/' : withoutBaseURL(URL_SERVER_USER + 'subscriptions/');
//    ===    COMMON DATA
export const URL_STACKS = URL_SERVER_APP + '/stacks/search';
export const URL_CLOUDS_CURRENCIES_CATEGORIES = URL_SERVER_APP + '/info/';
export const URL_NEXT_API_CLOUDS_CURRENCIES_CATEGORIES = '/api/ccc';
export const URL_PLANS_INFO = URL_SERVER_USER + 'plans/info/';
export const URL_QUESTIONNAIRE_DATA = URL_SERVER_APP + '/settings/';
//    ===    NEXT API
export const URL_NEXT_API_LOGIN = '/api/login';
export const URL_NEXT_API_DESIRE_REQUEST = '/api/request-desire';
export const URL_NEXT_API_ACCOUNT_RESTORE = '/api/account-restore';
export const URL_NEXT_API_EMAIL_CONFIRMATION_RESEND = '/api/email-confirmation-resend';
export const URL_NEXT_API_EMAIL_CONFIRMATION_CHANGE = '/api/email-confirmation-change';
export const URL_NEXT_API_PASSWORD_RECOVER = '/api/password-recover';
export const URL_NEXT_API_PASSWORD_RESET = '/api/password-reset';
export const URL_NEXT_API_QUESTIONNAIRE_DATA = '/api/questionnaire';
//
//    ===    NAV
//
export const NAV_STACK_LIST = '/applications';
export const NAV_DASHBOARD = '/dashboard';
export const NAV_PRICING = '/pricing';
export const NAV_BLOG = '/blog';
export const NAV_EXPLAINS = '/explains';
export const NAV_SIGN_IN = '/sign-in';
export const NAV_SIGN_UP = '/sign-up';
export const NAV_LOGOUT = '/logout';
export const NAV_SIGN_UP_INFO = '/sign-up-info';
export const NAV_PROFILE = '/me';
export const NAV_CLOUDS = '/clouds';
export const NAV_REQUEST_STACK = '/request';
export const NAV_PASSWORD_RECOVER = '/password/recover';
export const NAV_PAYMENT_SUCCESS = '/thanks';
// info pages
export const NAV_HOW_IT_WORKS = '/how-it-works';
export const NAV_WHAT_IS_TRYDIRECT = '/what-is-trydirect';
export const NAV_CONTACT = '/contact-us';
export const NAV_TEAM = '/about-us';
export const NAV_BRAND = '/brand';
export const NAV_DOCKER_CHEAT_SHEET = '/docker-cheat-sheet';
// law docs
export const NAV_COOKIE_POLICY = '/cookie-policy';
export const NAV_PRIVACY_POLICY = '/privacy-policy';
export const NAV_TERMS = '/terms';
export const NAV_RETURN_POLICY = '/return-policy';
export const NAV_AGREEMENT = '/agreement';
// service pages
export const NAV_EMAIL_UNCONFIRMED = '/unconfirmed-email';
export const NAV_ACCOUNT_COMPLETE = '/account/complete';
export const NAV_ACCOUNT_RESTORE = '/account/restore';
//
//    ===    OTHER
//
export const URL_CUSTOM_REQUEST = URL_SERVER_APP + '/request/';
export const URL_STACKS_RATING_GET = URL_SERVER_USER + 'stacks_rating/';
export const URL_STACKS_RATING_ADD = URL_SERVER_USER + 'stacks_rating/add';
//    ===    DASHBOARD
export const URL_WEBSOCKET = process.env.NEXT_PUBLIC_WEBSOCKET_PROTOCOL + process.env.NEXT_PUBLIC_URL_BASE + '/server/app/install/read/ws';
export const DEPLOYMENTS_LOGS_STORAGE_NAME = 'deploymentsLogs';
// export const INSTALLATION_LATEST_PROGRESS_NAME = 'installationLatestProgress';
//    ===    DEPLOYMENTS
export const URL_API_INSTALL = URL_SERVER_USER + 'install/';
// export const URL_API_INSTALL = !ENV_LOCAL ? URL_SERVER_USER + 'install/' : withoutBaseURL(URL_SERVER_USER + 'install/');
export const URL_NEXT_API_DEPLOYMENTS = '/api/deployments';
export const URL_DEPLOYMENT_DELETE = !ENV_LOCAL ? URL_API_INSTALL : withoutBaseURL(URL_API_INSTALL);
export const URL_DEPLOYMENT_CREATE = !ENV_LOCAL ? URL_API_INSTALL : withoutBaseURL(URL_API_INSTALL);
export const URL_DEPLOYMENT_VALIDATE = !ENV_LOCAL ? URL_API_INSTALL + 'pre-check' : withoutBaseURL(URL_API_INSTALL + 'pre-check');
export const URL_API_MIGRATE = URL_SERVER_USER + 'migrate/';
// export const URL_API_MIGRATE = !ENV_LOCAL ? URL_SERVER_USER + 'migrate/' : withoutBaseURL(URL_SERVER_USER + 'migrate/');
//    ===    PAYMENT
export const URL_SUBSCRIPTION_PAYMENT = !ENV_LOCAL ? URL_SERVER_USER + 'plans/' : withoutBaseURL(URL_SERVER_USER + 'plans/');
export const URL_REPEAT_PAYMENT = !ENV_LOCAL ? URL_SERVER_USER + 'install/pay/' : withoutBaseURL(URL_SERVER_USER + 'install/pay/');
export const URL_RESUME_DEPLOYMENT = !ENV_LOCAL ? URL_SERVER_USER + 'install/start_status_resume/' : withoutBaseURL(URL_SERVER_USER + 'install/start_status_resume/');
//    ===    PARTNERS
export const PARTNER_LINK_UPCLOUD = 'https://www.upcloud.com/register/?promo=29GFG3';
export const PARTNER_LINK_SCALEWAY = 'https://cloud.scaleway.com/#/signup';
export const PARTNER_LINK_GUVER = 'https://guver.com.br/';
export const PARTNER_LINK_FREELINUXBLOG = 'https://freelinuxblog.com/';
//    ===    EXTERNAL
// export const URL_FEATURE_REQUEST = 'https://bitbucket.org/Optimum-Web/try-direct-features-bugs/issues/';
// export const URL_FEEDBACK = 'https://bitbucket.org/Optimum-Web/try-direct-features-bugs/issues/';
export const HOWTO_CHANGE_DNS_GODADDY = 'https://www.godaddy.com/help/change-an-a-record-19239';
export const HOWTO_CHANGE_DNS_NAMECHEAP = 'https://www.namecheap.com/support/knowledgebase/article.aspx/319/2237/how-can-i-set-up-an-a-address-record-for-my-domain';
export const HOWTO_CHANGE_DNS_BLUEHOST = 'https://my.bluehost.com/hosting/help/whats-an-a-record';
export const HOWTO_CHANGE_DNS_NAME = 'https://www.name.com/support/articles/205188538-Pointing-your-domain-to-hosting-with-A-records';
export const HOWTO_CHANGE_DNS_DREAMHOST = 'https://support.cloudways.com/creating-a-record-dreamhost/';
export const HOWTO_CHANGE_DNS_A = 'https://www.google.com/search?q=change+%22A%22+record&oq=change+%22A%22+record+&aqs=chrome..69i57j0l5.10485j0j7&sourceid=chrome&ie=UTF-8';

export const URL_CONSULT_DEPLOYMENT = 'https://calendly.com/info-c2g/30min';
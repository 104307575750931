import React, { useEffect, useState } from 'react'
import Link from 'next/link';
import styles from './CookiesAccept.module.scss'
import Button from "../../../components/ripple/Button";
import { NAV_COOKIE_POLICY, NAV_PRIVACY_POLICY } from "../../../config";
import { CONSENT_HANDLED, CONSENT_TYPES } from "../../../helpers/analytics/googleAnalytics";
import Switch from "../../../components/Switch";

export default function CookiesAccept({}) {

    let defaults = {};
    CONSENT_TYPES.map(type => defaults[type.code] = type.defaults);
    const [showDialogue, setShowDialogue] = useState(false);
    const [types, setTypes] = useState(defaults);

    useEffect(() => {
        // if (!getCookie('allowCookies')) this.setState({showDialogue: true});
        if (localStorage.getItem(CONSENT_HANDLED) !== 'true') setShowDialogue(true);
    }, []);


    const acceptCookies = () => {
        // setCookie('allowCookies', true);
        setShowDialogue(false);
        localStorage.setItem(CONSENT_HANDLED, 'true');
        if (window.gtag) window.gtag('consent', 'update', types);
    };


    if (!showDialogue) return null;

    return (
        <div className={styles.cookies}>
            <div className={styles.cookies__content}>
                <p className={styles.text}>
                    We use cookies to offer you a better browsing experience,
                    analyze site traffic and personalize content.
                    You can read <Link href={NAV_PRIVACY_POLICY} target='_blank' prefetch={false}>our policy</Link> and <Link
                    href={NAV_COOKIE_POLICY} target='_blank'
                > cookie policy</Link>.
                </p>
                <div className={styles['cookies__content__container']}>
                    {
                        CONSENT_TYPES.map((type, index) => (
                            <Switch
                                className={styles.switch}
                                title={type.title}
                                description={type.description}
                                value={types[type.code] === 'granted'}
                                onChange={bool => setTypes({...types, ...{[type.code]: !!bool ? 'granted' : 'denied'}})}
                                key={index}
                            />
                        ))
                    }
                    <div className={styles.bottom}>
                        <Button
                            iconRight='fas fa-cookie-bite'
                            title='Accept'
                            onClick={acceptCookies}
                            visuals={{
                                colorBasic: ['var(--colorAccentContrast)']
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
import React, { useContext, useEffect, useRef, useState } from 'react'
import styles from './Header.module.scss'
import MobileHeader from "./mobile/MobileHeader";
import DesktopHeader from "./desktop/DesktopHeader";
import { UIContext } from "../UiHandler";

export default function Header({user, style, isMobile, width}) {

    const {scrollY} = useContext(UIContext)?.scroll;

    const [revealHeader, _setRevealHeader] = useState(true);

    const revealHeaderRef = useRef(revealHeader);

    const setRevealHeader = bool => {
        revealHeaderRef.current = bool;
        _setRevealHeader(bool);
    }

    const lastPositionRef = useRef(0);

    const handleScroll = (scrollY) => {

        if ((scrollY - lastPositionRef?.current > 0) && scrollY > 60 && !!revealHeaderRef.current) {
            setRevealHeader(false);
        }

        if ((lastPositionRef?.current - scrollY > 0) && !revealHeaderRef.current) {
            setRevealHeader(true);
        }

        lastPositionRef.current = scrollY;
    };

    useEffect(() => {
        handleScroll(scrollY)
    }, [scrollY])

    useEffect(() => {
        return () => {
            if (document.body.style.overflow === 'hidden') {
                setTimeout(() => document.body.style.overflow = 'auto', 0);
            }
        }
    }, []);

    return (
        <header
            className={styles.header}
            style={{
                ...{'--header-top': !!revealHeader ? '0' : isMobile ? '-60px' : '-70px'},
                ...style,
            }}
        >
            {
                isMobile
                    ? <MobileHeader user={user} isMobile={isMobile}/>
                    : <DesktopHeader user={user} isMobile={isMobile} width={width}/>
            }
        </header>
    )
}
import commonData from "./CommonDataReducer";

let initialState = [];

import {
    ADD_NOTIFICATION
} from '../actions/NotificationActions'

export let notifications = (state = initialState, action) => {
    switch (action.type) {
        case ADD_NOTIFICATION:
            return [
                ...state,
                ...[action.payload]
            ];
        default:
            return state
    }
};

export default notifications
let initialState = {
    layers: [],
    onClose: null,
    appContainerBlur: null,
    modalBackgroundColor: null,
    classNameApp: null,
    classNameModal: null,
    preventClose: false,
};

import {
    ADD_MODAL_LAYER,
    REMOVE_LAST_MODAL_LAYER,
    SET_MODAL_CONFIG,
    WIPE_MODAL_STATE,
} from '../actions/ModalActions'

let modal = (state = initialState, action) => {
    switch (action.type) {
        case ADD_MODAL_LAYER:
            return {
                ...state,
                ...{ layers: [...state.layers, ...[action.payload]] }
            };
        case REMOVE_LAST_MODAL_LAYER:
            return {
                ...state,
                ...{
                    layers: [
                        ...state.layers.slice(0, state.layers.length - 1),
                    ]
                }
            };
        case SET_MODAL_CONFIG:
            let initialButLayers = { ...initialState };
            delete initialButLayers.layers;
            return { ...state, ...initialButLayers, ...action.payload };
        case WIPE_MODAL_STATE:
            return initialState;
        default:
            return state
    }
};

export default modal
import React from 'react';
import PropTypes from 'prop-types'

export default class Loading extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        }
    }

    renderContent = () => {

        let {message, icon} = this.props;
        let animationType = 'top';
        let faCode = null;
        let svgLink = null;

        if (!!icon && !!Object.keys(icon).length) {
            animationType = icon.animationType;
            faCode = icon.faCode;
            svgLink = icon.svgLink;
        }

        let animation = `animated-loading-action-${animationType}`;

        return (
            <div className='animated-loading__content'>
                <div className="animated-loading__content__ring">
                    <div/><div/><div/><div/>
                    {
                        !!faCode &&
                        <i
                            className={`${faCode} animated-loading__content__ring__action ${animation}`}
                            aria-hidden="true"
                        />
                    }
                </div>
                {!!message && <div className='animated-loading__content__message'>{message}</div>}
            </div>
        )
    }

    renderCover = () => {

        let {cover, type = 'component'} = this.props;

        return (
            !!cover
            || !('cover' in this.props) && type !== 'component' // cover should be visible if not exactly "false" is passed
            || (!type || type === 'component') && !!cover
        ) ? <div className='animated-loading__cover'/> : null
    }

    render() {

        let {
            className, circleDiameter, type = 'component', contentFlow = 'column',
            coverOpacity, coverColor, coverBorderRadius, fontColor, colorCircle
        } = this.props;
        let typeClass = ` animated-loading-${type}`;

        if ((type === 'page' || type === 'page-cover') && !circleDiameter) circleDiameter = '8rem';

        return (
            <div
                className={`animated-loading${typeClass}${className ? ' ' + className : ''}`}
                style={{
                    '--circle-diameter' : circleDiameter,
                    '--content-flow' : contentFlow,
                    '--cover-opacity': coverOpacity,
                    '--cover-color': coverColor,
                    '--cover-border-radius': coverBorderRadius,
                    '--loading-font-color': fontColor,
                    '--loadingColorCircle': colorCircle,
                }}
            >
                {this.renderContent()}
                {this.renderCover()}
            </div>
        );
    }
}

Loading.propTypes = {
    className: PropTypes.string,
    message: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    contentFlow: PropTypes.oneOf(['row', 'column']),
    circleDiameter: (props, propName, componentName) => {
        if (!!props[propName] && (!props[propName].includes('rem') || !parseFloat(props[propName]) || !!props[propName].includes(" "))) {
            return new Error (
                `Invalid "${propName}" value supplied to ${componentName}. "${propName}" supports only a number with "rem" unit in the end.`
            )
        }
    },
    // active: PropTypes.bool,
    cover: PropTypes.bool,
    coverOpacity: PropTypes.number,
    coverColor: PropTypes.string,
    coverBorderRadius: PropTypes.string,
    fontColor: PropTypes.string,
    colorCircle: PropTypes.string,
    type: PropTypes.oneOf(['component-cover', 'component', 'page-cover', 'page']),
    icon: PropTypes.shape({
        faCode : PropTypes.string,
        svgLink : PropTypes.string,
        animationType: PropTypes.oneOf(['top', 'left', 'right', 'bottom', 'pulse'])
    })
}
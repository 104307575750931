import * as config from '../../config';
// import FormData from "form-data";
import { cliColor } from "../cli/colors";

const checkArgumentsTypes = fetchConfig => {

    const requiredTypes = {
        method: ['string'],
        URL: ['string'],
        onSuccess: ['function'],
        onError: ['function'],
        headers: ['object'],
        body: ['object', 'string'],
        requestTitle: ['string']
    };

    Object.keys(fetchConfig).map(property => {
        if (
            Object.keys(requiredTypes).includes(property)
            && !requiredTypes[property].includes(typeof fetchConfig[property])
        ) {
            console.group(`Possible error during Fetch request (${fetchConfig.URL})`);
            console.warn(
                `Type of property "${property}" is invalid : `
                + `expected "${requiredTypes[property]}", but received "${typeof fetchConfig[property]}".`
            );
            console.groupEnd();
        }

        if (!Object.keys(requiredTypes).includes(property)) {
            console.group(`Warning during Fetch request (${fetchConfig.URL})`);
            console.warn(`Unexpected property "${property}". It will be ignored.`);
            console.groupEnd();
        }
    })
};

export async function createFetchAsync({
                                           method: method = 'GET',
                                           URL: URL,
                                           requestTitle: requestTitle = null,
                                           headers: headers,
                                           onSuccess: onSuccess,
                                           onError: onError,
                                           body: body = null,
                                           bodyFormData: bodyFormData = false,
                                       }, success, error) {

    let requestConfig = {
        method: method,
        headers: headers ? headers : undefined,
    };

    if (body && typeof body === 'object') {
        requestConfig.body = JSON.stringify(body);
    } else if (body && typeof body !== 'object') {
        requestConfig.body = body;
    }

    if (config.DEBUG && URL) checkArgumentsTypes(arguments[0]);

    if (!!bodyFormData) {
        let getFormData = object => {
            let body = new FormData();
            Object.keys(object).forEach(key => body.append(key, object[key]));
            return body;
        };

        requestConfig.body = getFormData(JSON.parse(body));
    }

    let handledResponse = {
        textData: null,
        status: null,
        success: null,
        statusText: null,
        url: null,
        data: null,
    };
    let unhandledSuccess = null;
    let unhandledError = null;

    await fetch(URL, requestConfig)
        .then(async response => {
            if (!response.ok) throw response;
            if (response.status === 204) return null;
            unhandledSuccess = response;
            handledResponse = {
                success: response.ok,
                status: response.status,
                data: await response.json(),
            }
        }).catch(async error => {

            let parseText = async _error => {
                let textData;
                try {
                    let text = await _error.text();
                    try {textData = JSON.parse(text)}
                    catch (e) {textData = text}
                } catch (e) {textData = null;}
                return textData
            };
            let textData = await parseText(error);

            unhandledError = error;
            handledResponse = {
                textData: textData ? textData : null,
                status: error.status ? error.status : null,
                success: error.ok ? error.ok : false,
                statusText: error.statusText ? error.statusText : null,
                url: error.url ? error.url : null,
                data: null,
            };
        });


    if (handledResponse.status === 200) {
        if (onSuccess) onSuccess(unhandledSuccess)
        return handledResponse
    } else {
        console.log();
        console.group();
        console.log(cliColor('------------------------------', 'red'));
        console.log(cliColor(`Error occurred while fetching`, 'red'))
        console.log(cliColor(`${URL}`, 'red'))
        console.log(cliColor('------------------------------', 'red'));
        console.group();
        console.log(handledResponse)
        console.groupEnd();
        console.log(cliColor('------------------------------', 'red'));
        console.groupEnd();
        if (onError) onError(unhandledError);
        // throw handledResponse
        // throw unhandledError
        throw(`
        ${handledResponse.url}
        ${handledResponse.status} ${handledResponse.statusText}
        ${handledResponse.textData?._error?.message || ''}`
        )
    }
}
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CDN_ENABLED, CDN_SUBDOMAIN, DEBUG, ENV_LOCAL, URL_BASE } from "../config";
import Image from "next/image";
// import Image from "next/image";

export default function ImgCDN({
                                   src, alt, className, fallbackImage, loadingRender,
                                   testImage, draggable, onRequestComplete,
                                   height, width, optimize, fill,
                                   ...props
                               }) {

    const [source, setSource] = useState(undefined);

    // const getImageStatus = ({
    //                             origin,
    //                             onError,
    //                             src,
    //                         }) => {
    //     let image = new Image();
    //     image.onload = () => {
    //         setSource(origin)
    //         if (!!onRequestComplete) onRequestComplete({success: true, origin});
    //     };
    //     image.onerror = () => {
    //         setSource(null);
    //         if (DEBUG && origin === 'CDN') console.warn(`Failed to load image with url "${src}" from ${origin}. Trying to get image from server...`);
    //         if (DEBUG && origin === 'server') console.warn(`Failed to load image with url "${src}" from ${origin}. Looks like requested image doesn't exist.`);
    //         if (!!onRequestComplete) onRequestComplete({success: false, origin});
    //         if (onError) onError();
    //     };
    //     image.src = src;
    // };

    // const _testImage = () => {
    //
    //     let urlCDN = CDN_SUBDOMAIN + src;
    //
    //     if (!!CDN_ENABLED) {
    //         getImageStatus({
    //             onError: () => getImageStatus({src: src, origin: 'server'}),
    //             src: urlCDN,
    //             origin: 'CDN'
    //         })
    //     } else {
    //         getImageStatus({src: src, origin: 'server'})
    //     }
    // };

    useEffect(() => {
        // if (!!testImage) {
        //     _testImage();
        // } else {
        setSource(CDN_ENABLED ? 'CDN' : 'server')
        // }
    }, [src, testImage]);

    let handledSrc;

    switch (true) {
        case ENV_LOCAL && source !== null:
            handledSrc = src?.includes('/files') ? URL_BASE + src : src;
            break;
        case ENV_LOCAL && source === null && !!fallbackImage:
            handledSrc = fallbackImage?.includes('/files') ? URL_BASE + fallbackImage : fallbackImage;
            break;
        case !testImage && !!CDN_ENABLED: // if no testing try to load directly from CDN
            handledSrc = CDN_SUBDOMAIN + src;
            break;
        case source === 'CDN': // all OK image can be rendered from CDN
            handledSrc = CDN_SUBDOMAIN + src;
            break;
        case source === null && !!fallbackImage: // Image possibly doesn't exist neither on CDN nor on server
            handledSrc = fallbackImage;
            break;
        default: // if error image wasn't provided, let it be handled by default browser tools
            handledSrc = src;
            break;
    }
    // if testing is on as well as loading component received and image is not yet loaded show "plug"
    // if (!!loadingRender && !!testImage && origin === null) {
    //     return loadingRender
    // }

    // return (
    //     <Image
    //         className={className}
    //         src={handledSrc}
    //         alt={alt}
    //         draggable={draggable}
    //         aria-hidden={props['aria-hidden']}
    //         aria-label={props['aria-label']}
    //         height={height || 50}
    //         width={width || 50}
    //     />
    // )

    const commonProps = {
        className: className,
        src: handledSrc,
        alt,
        draggable,
        ['aria-hidden']: props['aria-hidden'],
        ['aria-label']: props['aria-label'],
    }

    if (optimize && height && width || !!fill) {
        return (
            <Image
                // className={className}
                // src={handledSrc}
                // alt={alt}
                // draggable={draggable}
                // aria-hidden={props['aria-hidden']}
                // aria-label={props['aria-label']}
                fill={fill}
                height={height}
                width={width}
                {...commonProps}
                {...props}
            />
        );
    } else {
        if (!!DEBUG && !handledSrc.includes('.svg')) console.info(`image ${handledSrc} is not optimized for production.`);
        return (
            <img
                // className={className}
                // src={handledSrc}
                // alt={alt}
                // draggable={draggable}
                // aria-hidden={props['aria-hidden']}
                // aria-label={props['aria-label']}
                // height={height}
                // width={width}
                {...commonProps}
                {...{
                    ...props,
                    ...{fill: undefined, priority: undefined}
                }}
            />
        );
    }
}

ImgCDN.propTypes = {
    src: PropTypes.string,
    className: PropTypes.string,
    alt: PropTypes.string,
    fallbackImage: PropTypes.string, // if requested image wasn't found by tests, provided error image url will be used
    onRequestComplete: PropTypes.func, // return image loading state to parent component
    testImage: PropTypes.bool, // defines if background image requests will fire
    loadingRender: PropTypes.element, // replaces render on initial loading
    draggable: PropTypes.string,
    ['aria-hidden']: PropTypes.string,
    ['aria-label']: PropTypes.string,
};
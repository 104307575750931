import { useEffect, useState } from "react";
import { LS_THEME_NAME, LS_THEME_VERSION_NAME, VERSION_ENGINE_THEME } from "../../../helpers/ui/ui";

export function useThemeVersion() {

    const [version, setVersion] = useState(undefined);

    useEffect(() => {
        setVersion(
            !isNaN(parseFloat(localStorage.getItem(LS_THEME_VERSION_NAME))) ? parseFloat(localStorage.getItem(LS_THEME_VERSION_NAME)) : 0
        )
    }, []);

    return version
}

export function useStoredThemeData(themeVersion) {
    const [data, setData] = useState(undefined);

    useEffect(() => {
        setData(
            themeVersion < VERSION_ENGINE_THEME ? {} : JSON.parse(localStorage.getItem(LS_THEME_NAME) || '{}')
        )
    }, []);

    return data
}
import React, { useEffect } from 'react'
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { supportsPassive } from "../../../../helpers/polyfills/eventListeners";
import Button from "../../../../components/ripple/Button";
import styles from './NotificationItem.module.scss'

function NotificationItem({notificationCenter, notification, clearTimers, resetTimers, stop, hide, dark}) {

    const handleMouseOver = () => {
        // allows to show notification as long as user points it with mouse or touches by sensor
        clearTimers()
    };

    const handleMouseOut = () => {
        // resume default flow if user has left pointing it with mouse or touching by sensor
        resetTimers()
    };

    const removeEventListeners = () => {
        if (notification) {
            notificationCenter.removeEventListener('mouseover', handleMouseOver);
            notificationCenter.removeEventListener('mouseout', handleMouseOut);
            notificationCenter.removeEventListener('touchstart', handleMouseOver);
            notificationCenter.removeEventListener('touchend', handleMouseOut);
        }
    };

    useEffect(() => {
        return () => {removeEventListeners()}
    })

    useEffect(() => {

        const userSupportsPassive = supportsPassive();

        if (notification) {
            notificationCenter.addEventListener('mouseover', handleMouseOver);
            notificationCenter.addEventListener('mouseout', handleMouseOut);
            notificationCenter.addEventListener(
                'touchstart',
                handleMouseOver,
                userSupportsPassive ? {passive: true} : false
            );
            notificationCenter.addEventListener('touchend', handleMouseOut)
        } else {
            removeEventListeners()
        }
    }, [notification]);

    const handleActionButtonClick = (e, action) => {
        e.stopPropagation();
        e.preventDefault();
        // props.resetTimers();
        action();
        stop();
    };

    const onContainerClick = () => {
        // Removing listeners is important for proper immediate notification closing
        removeEventListeners();
        stop()
    };

    const onContextMenu = event => {
        event.preventDefault();
        event.stopPropagation();
        return false;
    };

    if (!notification) return null;

    let {message, onRetry = null, type, buttons} = notification;
    let isHiding = hide ? ` ${styles['container--hide']}` : '';

    let icon = 'fas fa-question-circle';

    if (type === 'error') icon = 'fas fa-exclamation-triangle';
    if (type === 'warning') icon = 'fas fa-exclamation-circle';
    if (type === 'success') icon = 'fas fa-check-circle';
    if (type === 'info') icon = 'fas fa-question-circle';

    const classType = ` ${styles[`container--${type}`]}`;

    return (
        <div
            className={`${styles.container}${classType}${isHiding}`}
            onClick={onContainerClick}
            onContextMenu={onContextMenu}
        >
            <div className={styles.container__message}>
                <i className={`${icon} ${styles.container__message__icon}`}/>
                <p className={styles.container__message__text}>{message}</p>
            </div>
            {
                (onRetry || !!buttons?.length) && // this check is for case when more buttons would be required. If new button type would appear check for it should be added here as well as in render for that one
                <div className={styles.container__buttons}>
                    {
                        onRetry &&
                        <Button
                            title='Retry'
                            displayType='text'
                            visuals={{colorBasic: ['var(--colorBackgroundContent)']}}
                            onClick={e => handleActionButtonClick(e, onRetry)}
                        />
                    }
                    {
                        !!buttons?.length &&
                        buttons.map((button, index) => (
                            <Button
                                title={button.title}
                                icon={button.icon}
                                displayType='text'
                                visuals={{colorBasic: ['var(--colorBackgroundContent)']}}
                                onClick={e => handleActionButtonClick(e, button.onClick)}
                                key={index}
                            />
                        ))
                    }
                </div>
            }
        </div>
    );
}

const mapStateToProps = state => ({
    dark: state.UI.dark,
})

export default connect(mapStateToProps)(NotificationItem)

NotificationItem.propTypes = {
    notification: PropTypes.shape({
        message: PropTypes.string.isRequired,
        requestTitle: PropTypes.string,
        responseContent: PropTypes.object,
        url: PropTypes.string,
        status: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        errorFetch: PropTypes.object,
        statusInfo: PropTypes.string,
        autoDismiss: PropTypes.number,
        type: PropTypes.string,
        onRetry: PropTypes.func,
        hide: PropTypes.bool
    })
};
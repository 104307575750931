export const
    SET_CURRENT_PLAN = 'SET_CURRENT_PLAN',
    SET_SELECTED_PLAN = 'SET_SELECTED_PLAN',
    WIPE_PAYMENT_DATA = 'WIPE_PAYMENT_DATA'
;

export function setCurrentPlan(data) {
    return {
        type : SET_CURRENT_PLAN,
        payload : data
    }
}

export function setSelectedPlan(data) {
    return {
        type : SET_SELECTED_PLAN,
        payload : data
    }
}

export function wipePaymentData() {
    return {
        type : WIPE_PAYMENT_DATA,
        payload : null
    }
}
let initialState = {
    user: undefined,
    currentPlan: null,
    userFetchStatus: undefined,
    deployments: [],
    deploymentsStatus: undefined,
};

import {
    UPDATE_USER_DATA,
    UPDATE_USER_PLAN,
    UPDATE_USER_STATUS,
    UPDATE_DEPLOYMENTS,
    UPDATE_DEPLOYMENTS_STATUS,
    WIPE_USER_DATA,
} from '../actions/UserActions'

let userData = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_USER_DATA :
            return {
                ...state,
                user: {
                    ...(state.user ? state.user : {}),
                    ...action.payload
                }
            };
        case UPDATE_USER_PLAN :
            return {
                ...state, currentPlan: action.payload
            };
        case UPDATE_USER_STATUS :
            let status = action.payload;
            if (
                action.payload !== undefined
                && action.payload !== 'success'
                && action.payload !== 'loading'
                && action.payload !== 'error'
            ) {
                status = state.userFetchStatus;
                console.error(`Invalid status "${action.payload}" sent to UPDATE_USER_FETCH_STATUS action. "userFetchStatus" will remain unchanged`)
            }
            return {...state, userFetchStatus: status};
        case UPDATE_DEPLOYMENTS:
            return {
                ...state,
                ...{deployments: action.payload}
            };
        case UPDATE_DEPLOYMENTS_STATUS:
            return {
                ...state,
                ...{deploymentsStatus: action.payload}
            };
        case WIPE_USER_DATA :
            return initialState;
        default:
            return state
    }
};

export default userData
import React from "react";
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { removeLastModalLayer, setModalConfig } from "../../../redux/actions/ModalActions"

class ModalLayout extends React.Component {

    constructor(props) {
        super(props);

        this.state = {}
    }

    handleClickOutsideModal = e => {

        const { onClose, preventClose } = this.props;

        if (preventClose) return;

        if (!this.lastLayer.contains(e.target)) {
            this.props.dispatch(removeLastModalLayer());
            if (onClose) onClose();
        }
    }

    handleEscapePress = e => {

        const { onClose, preventClose } = this.props;

        if (preventClose) return;

        const escapePressed = e.keyCode === 27 || e.keyCode === 'Escape';

        if (escapePressed) {
            this.props.dispatch(removeLastModalLayer());
            if (onClose) onClose();
        }
    }

    setConfig = layers => {

        let lastLayer = { ...layers[layers.length - 1] };
        delete lastLayer.component;
        this.props.dispatch(setModalConfig(lastLayer));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        let { layers } = this.props;

        if (layers !== prevProps.layers) {
            // handle event listeners
            if (!!layers.length) {
                this.modalLayout.addEventListener('mousedown', this.handleClickOutsideModal);
                document.addEventListener('keyup', this.handleEscapePress);
            } else {
                this.modalLayout.removeEventListener('mousedown', this.handleClickOutsideModal);
                document.removeEventListener('keyup', this.handleEscapePress);
            }
            this.setConfig(layers);
        }
    }

    componentWillUnmount() {
        this.modalLayout.removeEventListener('mousedown', this.handleClickOutsideModal);
        document.removeEventListener('keyup', this.handleEscapePress);
    }

    renderLayers = () => {

        let { layers } = this.props;

        return layers.map((layer, index) => {

            let {classNameLayer, variables} = layer;
            let cover = layers.length > 1 && index < layers.length - 1 ? ' modal-layout__layer--covered' : '';

            return (
                <div
                    className={`modal-layout__layer${cover}${!!classNameLayer ? ' ' + classNameLayer : ''}`}
                    style={{
                        ...{'zIndex': index + 2},
                        ...(variables ? variables : {}),
                    }}
                    onClick={e => e.stopPropagation()}
                    ref={el => index === layers.length - 1 ? this.lastLayer = el : undefined}
                    key={index}
                >
                    <div className={'modal-layout__layer__content'}>
                        {layer.component}
                    </div>
                </div>
            );
        });
    }

    render() {

        let { layers, modalBackgroundColor, layerBackgroundBlur, appContainerBlur, classNameModal } = this.props;
        let showLayout = !!layers.length ? ' modal-layout--reveal' : '';
        let variables = {};
        let passedClass = classNameModal ? ' ' + classNameModal : '';

        if (!!modalBackgroundColor) {
            let light, dark;
            if (Array.isArray(modalBackgroundColor)) {
                light = modalBackgroundColor[0];
                dark = modalBackgroundColor[1] ? modalBackgroundColor[1] : modalBackgroundColor[0];
            } else {
                light = modalBackgroundColor;
                dark = modalBackgroundColor;
            }
            variables = {
                ...variables,
                ...{
                    '--modal-layout-background-color-light': light,
                    '--modal-layout-background-color-dark': dark,
                },
            };
        }

        if (!!appContainerBlur) variables = { ...variables, ...{ '--layer-background-blur': appContainerBlur } };
        if (!!layerBackgroundBlur) variables = { ...variables, ...{ '--layer-background-blur': layerBackgroundBlur } };

        return (
            <div
                className={`modal-layout${showLayout}${passedClass}`}
                style={variables}
                ref={el => this.modalLayout = el}
            >
                <div
                    className='modal-layout__cover'
                    style={{ 'zIndex': layers.length + 1 }}
                />
                {this.renderLayers()}
            </div>
        );
    }
}

let mapStateToProps = state => ({
    layers: state.modal.layers,
    onClose: state.modal.onClose,
    modalBackgroundColor: state.modal.modalBackgroundColor,
    appContainerBlur: state.modal.appContainerBlur,
    layerBackgroundBlur: state.modal.layerBackgroundBlur,
    classNameAppContainer: state.modal.classNameAppContainer,
    classNameModal: state.modal.classNameModal,
    variables: state.modal.variables,
    preventClose: state.modal.preventClose,
})

export default connect(mapStateToProps)(ModalLayout)

ModalLayout.propTypes = {
    layers: PropTypes.arrayOf(
        PropTypes.shape({
            component: PropTypes.element, // component to render
            onClose: PropTypes.func, // function called when modal closes
            appContainerBlur: PropTypes.string, // defines blur strength of app layout (in background)
            // modalBackgroundColor: PropTypes.string, // defines background color for modal's cover
            modalBackgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
            layerBackgroundBlur: PropTypes.string, // defines blur strength of covered layer
            classNameLayer: PropTypes.string,
            classNameModal: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
            classNameAppContainer: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
            variables: PropTypes.object,
            preventClose: PropTypes.bool,
        })
    ),
    onClose: PropTypes.func, // inner logic, do not pass this
    appContainerBlur: PropTypes.string, // inner logic, do not pass this
    modalBackgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]), // inner logic, do not pass this
    layerBackgroundBlur: PropTypes.string, // inner logic, do not pass this
    classNameAppContainer: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // inner logic, do not pass this
    classNameModal: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // inner logic, do not pass this
    variables: PropTypes.object, // inner logic, do not pass this
    preventClose: PropTypes.bool, // inner logic, do not pass this
}
import React from "react";
import PropTypes from 'prop-types';
import styles from './Content.module.scss';

export function Content({className, style, stretch, children, padding, margin, backgroundColor, maxWidth, noSection}) {

    const _style = {
        ...style,
        ...(!!padding ? {padding} : {}),
        ...(!!margin ? {margin} : {}),
        ...(!!backgroundColor ? {backgroundColor} : {}),
        ...(!!maxWidth && !stretch ? {maxWidth} : {}),
    };

    if (!!noSection) {
        return (
            <div
                className={`${!stretch ? styles.contentCenter : styles.contentStretch} ${className ? ' ' + className : ''}`}
                style={_style}
            >
                {children}
            </div>
        )
    }

    return (
        <section
            className={`${!stretch ? styles.contentCenter : styles.contentStretch} ${className ? ' ' + className : ''}`}
            style={_style}
        >
            {children}
        </section>
    )
}

Content.propTypes = {
    className: PropTypes.string,
    stretch: PropTypes.bool,
    noSection: PropTypes.bool,
    padding: PropTypes.string,
    margin: PropTypes.string,
    backgroundColor: PropTypes.string,
}


export function Block({className, style, children, padding, margin, backgroundColor}) {

    const _style = {
        ...style,
        ...(!!padding ? {padding} : {}),
        ...(!!margin ? {margin} : {}),
        ...(!!backgroundColor ? {backgroundColor} : {}),
    };

    return (
        <div
            className={`${styles.block} ${className ? ' ' + className : ''}`}
            style={_style}
        >
            {children}
        </div>
    )
}

Block.propTypes = {
    className: PropTypes.string,
    padding: PropTypes.string,
    margin: PropTypes.string,
    backgroundColor: PropTypes.string,
}


export default function Page({className, style, children, padding, margin, backgroundColor}) {

    const _style = {
        ...style,
        ...(!!padding ? {padding} : {}),
        ...(!!margin ? {margin} : {}),
        ...(!!backgroundColor ? {backgroundColor} : {}),
    };

    return (
        <div
            className={`${styles.container} ${className ? ' ' + className : ''}`}
            style={_style}
        >
            {children}
        </div>
    )
}

Page.propTypes = {
    className: PropTypes.string,
    padding: PropTypes.string,
    margin: PropTypes.string,
    backgroundColor: PropTypes.string,
}
import { store } from '../../../../redux/store'
import { addNotification } from '../../../../redux/actions/NotificationActions';

function getTime() {

    let normalizeValue = value => value.toString().length === 1 ? '0' + value : value;
    let date = new Date();
    let minutes = date.getMinutes();
    let hour = date.getHours();
    let seconds = date.getSeconds();
    let milliseconds = date.getMilliseconds();

    return normalizeValue(hour) + ':' + normalizeValue(minutes) + ':' + normalizeValue(seconds) + '.' + milliseconds;
}

function handleType(type) {
    let allowedTypeValues = ['error', 'success', 'warning', 'info'];
    return allowedTypeValues.some(value => value === type) ? type : `invalid`; // Check if we can handle passed type
}

function handleMessage(message, requestTitle, responseContent) {

    let handledMessage = message && message;

    let responseHaveSomethingToSay = responseContent
        && typeof responseContent === 'object'
        && responseContent.hasOwnProperty('message')
        && typeof responseContent['message'] === 'string'
        && !!responseContent['message']
    ;

    switch (true) {
        case !message && responseHaveSomethingToSay:
            handledMessage = responseContent['message'];
            break;
        case !message && !!requestTitle:
            handledMessage = requestTitle + ' has been failed to load';
            break;
        case !message && !requestTitle && !responseHaveSomethingToSay:
            handledMessage = 'Sorry, something unexpected happened. Application might work improperly';
            break;
        default:
            break;
    }

    return handledMessage
}

// === API for dispatchNotification({}) ===
//
// message           - the first priority argument. If passed it will be the message for user to see.
//
// requestTitle      - if message wasn't passed, notification will search for requestTitle to show user what request
//                     failed (in case request should be handled).
//
// responseContent   - expects json object with property "message" to auto-handle notification if message or
//                     requestTitle arguments is absent. If no condition fulfilled (not an object || haven't "message"
//                     property) it will show to user a common error message. This is the last attempt to handle the user message.
//
// url               - expects request url if this is a request. Optional. Mostly for DevLogs.
//
// status            - expects unhandled (not a json() or text() or else()) error object from fetchRequest with status and
//                     statusText properties. Optional. Mostly for DevLogs.
//
// autoDismiss       - number for message dismiss timeout. Will be multiplied by 1000 in NotificationCenter. Optional.
//                     If not not provided, will be set to default value.
//
// type              - type of notification. Can be "error" || "warning" || "success". Optional.
//                     If not provided, will be set to "error".
//
// onRetry           - expects function that will fire on user demand as argument. Will trigger "Retry" button render. Optional.

export function dispatchNotification({
                                         message: message = null,
                                         requestTitle: requestTitle = null,
                                         responseContent: responseContent = null,
                                         url: url = null,
                                         status: status = null,
                                         autoDismiss: autoDismiss = null,
                                         type: type = 'error',
                                         onRetry: onRetry = null,
                                         buttons: buttons = [],
                                         errorFetch,
                                     }) {

    const notification = {
        message: handleMessage(message, requestTitle, responseContent),
        url: url,
        autoDismiss: autoDismiss,
        status: status && status['status'] && status['status'],
        statusInfo: status && status['statusText'] && status['statusText'],
        type: handleType(type),
        time: getTime(),
        onRetry: onRetry,
        buttons: buttons,
        errorFetch,
    };

    store.dispatch(addNotification(notification))
}
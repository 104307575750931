import {combineReducers} from 'redux';
import commonData from './CommonDataReducer';
import userData from './UserReducer';
import paymentReducer from "./PaymentReducer";
import modal from './ModalReducer';
import notifications from './NotificationsReducer';
import errorsLog from './ErrorsLogReducer';
import UIReducer from './UIReducer';
import deploymentReducer  from './DeploymentReducer'

const rootReducer = combineReducers({
    commonData: commonData,
    userData: userData,
    payment: paymentReducer,
    deployment: deploymentReducer,
    modal: modal,
    notifications: notifications,
    errorsLog: errorsLog,
    UI: UIReducer,
});

export default rootReducer;
export const
    UPDATE_USER_DATA = 'UPDATE_USER_DATA',
    UPDATE_USER_PLAN = 'UPDATE_USER_PLAN',
    UPDATE_USER_STATUS = 'UPDATE_USER_STATUS',
    UPDATE_DEPLOYMENTS = 'UPDATE_DEPLOYMENTS',
    UPDATE_DEPLOYMENTS_STATUS = 'UPDATE_DEPLOYMENTS_STATUS',
    WIPE_USER_DATA = 'WIPE_USER_DATA'
;

export function updateUserData(data) {
    return {
        type: UPDATE_USER_DATA,
        payload: data
    }
}
export function updateUserPlan(data) {
    return {
        type: UPDATE_USER_PLAN,
        payload: data
    }
}

export function updateUserFetchStatus(statusString) {
    return {
        type: UPDATE_USER_STATUS,
        payload: statusString
    }
}

export function updateDeployments(data) {
    return {
        type: UPDATE_DEPLOYMENTS,
        payload: data
    }
}

export function updateDeploymentsStatus(data) {
    return {
        type: UPDATE_DEPLOYMENTS_STATUS,
        payload: data
    }
}

export function wipeUserData(data) {
    return {
        type: WIPE_USER_DATA,
        payload: data
    }
}
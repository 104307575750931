import { updateTheme } from '../../redux/actions/UIActions';
import { store } from '../../redux/store';
import { openSans, roboto } from "../../pages/_app";

export const setCssVariables = object => {
    Object.keys(object).map(variable => {
        document.documentElement.style.setProperty('--' + variable, object[variable])
    });
}

export const RESOLUTION_BREAKPOINTS = {
    phone : 490,
    tablet : 830,
    desktop : 1920,
}

export const LS_THEME_NAME = 'theme';
export const LS_THEME_VERSION_NAME = 'theme_version'
export const VERSION_ENGINE_THEME = 0.1;
export const SYSTEM_THEME_INDEX = 1;
export const SYSTEM_THEME_NAME = 'System theme';
export const LIGHT_THEME_INDEX = 2;
export const LIGHT_THEME_NAME = 'Light theme';
export const DARK_THEME_INDEX = 3;
export const DARK_THEME_NAME = 'Dark theme';

export const DARK_THEME = {
    // accent
    colorAccentSoft: '#352c45',
    colorAccentLight: '#7e57c2',
    colorAccentBasic: '#A684E1',
    colorAccentContrast: '#b7a1e0',
    // background
    colorBackgroundBasic: '#141414',
    colorBackgroundBasicLight: '#191919',
    colorBackgroundContent: '#202020',
    colorBackgroundTop: '#383838',
    // -- status
    colorBackgroundSuccess: '#183b29',
    colorBackgroundWarning: '#472909',
    colorBackgroundError: '#4d2222',
    colorBackgroundInfo: '#093f5a',
    colorBackgroundDisabled: '#262626',
    // font
    colorFontDisabled: '#606060',
    colorFontLight: '#888888',
    colorFontCommon: '#ababab',
    colorFontTitle: '#e3e2e2',
    colorFontContrast: '#fff',
    // -- status
    colorFontSuccess: '#66bb6a',
    colorFontWarning: '#f57c00',
    colorFontError: '#f14a46',
    colorFontInfo: '#039be5',
};

export const LIGHT_THEME = {
    // accent
    colorAccentSoft: '#eadeff',
    colorAccentLight: '#A684E1',
    colorAccentBasic: '#7e57c2',
    colorAccentContrast: '#673ab7',
    // background
    colorBackgroundBasic: '#eaf1fb',
    colorBackgroundBasicLight: '#f6f8fc',
    colorBackgroundContent: '#fff',
    colorBackgroundTop: '#fff',
    // -- status
    colorBackgroundSuccess: '#d9e9de',
    colorBackgroundWarning: '#fdeed0',
    colorBackgroundError: '#ffe7e7',
    colorBackgroundInfo: '#eaf1fb',
    colorBackgroundDisabled: '#e2e2e2',
    // font
    colorFontDisabled: '#9b9b9b',
    colorFontLight: '#757575',
    colorFontCommon: '#585858',
    colorFontTitle: '#333',
    colorFontContrast: '#202020',
    // -- status
    colorFontSuccess: '#26a69a',
    colorFontWarning: '#ff8f00',
    colorFontError: '#dd3633',
    colorFontInfo: '#146fbe',
};

export function setThemeVariables(isDark) {

    const preset = isDark ? DARK_THEME : LIGHT_THEME;
    const storedType = JSON.parse(localStorage.getItem(LS_THEME_NAME) || '{}')?.type;
    // set variables from defined settings to css with prefix "--"
    setCssVariables(preset);
    // save colors to use theme on page restart. localStoredType additionally to be able to rewrite full object instead of concat
    window.localStorage.setItem(LS_THEME_NAME, JSON.stringify({...preset, ...(storedType ? {type: storedType} : {})}));
    // set attribute to document for css to change styles dynamically
    document.documentElement.setAttribute('data-theme', isDark ? 'dark' : 'light');
    // set data to common storage
    store.dispatch(updateTheme({...preset, ...{dark: isDark}}));
}

export const UI_GEOMETRY = {
    // ===  LAYOUT  ===
    paddingPage: '20px',
    // outer
    borderRadiusContainerOuter: '28px',
    paddingContainerOuter: '8px',
    // inner
    borderRadiusContainerInnerEdge: '24px',
    borderRadiusContainerInner: '4px',
    paddingContainerInner: '20px',
    marginContainerInner: '2px',
    // block
    borderRadiusBlock: '15px',
    // paddingBlock: '20px',
    // marginBlock: '10px',
}

export const UI_FONTS_STATIC = {
    // fontFamilyPrimary: 'Open Sans, sans-serif',
    fontFamilyPrimary: openSans.style.fontFamily,
    // fontFamilySecondary: 'Roboto, sans-serif',
    fontFamilySecondary: roboto.style.fontFamily,
}

export const UI_FONTS_DESKTOP = {
    // primary
    fontSizePrimaryTiny: '1.2rem',
    fontSizePrimarySmall: '1.4rem',
    fontSizePrimary: '1.6rem',
    fontSizePrimaryBig: '1.8rem',
    fontSizePrimaryLarge: '2rem',
    lineHeightPrimaryTiny: 1.2,
    lineHeightPrimarySmall: 1.4,
    lineHeightPrimary: 1.6,
    lineHeightPrimaryBig: 1.8,
    lineHeightPrimaryLarge: 2,
    fontWeightPrimaryThin: 400,
    fontWeightPrimaryRegular: 600,
    fontWeightPrimaryBold: 700,
    fontWeightPrimaryBoldExtra: 800,
    // secondary
    fontSizeSecondaryTiny: '1.1rem',
    fontSizeSecondarySmall: '1.3rem',
    fontSizeSecondary: '1.5rem',
    fontSizeSecondaryBig: '1.7rem',
    fontSizeSecondaryLarge: '1.9rem',
    lineHeightSecondaryTiny: 1.1,
    lineHeightSecondarySmall: 1.3,
    lineHeightSecondary: 1.5,
    lineHeightSecondaryBig: 1.7,
    lineHeightSecondaryLarge: 1.9,
    fontWeightSecondaryThin: 300,
    fontWeightSecondaryRegular: 400,
    fontWeightSecondaryBold: 500,
    fontWeightSecondaryBoldExtra: 700,
}

export const ANIMATIONS_UI = {
    // ===  ANIMATIONS  ===
    transitionTimingFunctionDefault: 'cubic-bezier(.44, .44, .19, .68)',
    transitionTimingFunctionDefaultInverse: 'cubic-bezier(.68, .19, .44, .44)',
    transitionDurationDefault: '100ms',
    transitionDefault: '100ms cubic-bezier(.44, .44, .19, .68)',
}
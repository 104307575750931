
let initialState = [];

import {
    ADD_ERROR,
    WIPE_ERRORS_LOG,
} from '../actions/ErrorsLogActions'

export let errorsLog = (state = initialState, action) => {
    switch (action.type) {
        case ADD_ERROR:
            return [
                ...state,
                ...[action.payload]
            ];
        case WIPE_ERRORS_LOG:
            return initialState;
        default:
            return state
    }
};

export default errorsLog
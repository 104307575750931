export const SOCIAL_DATA = [
    {
        code: 'fb',
        name: 'Facebook',
        color: ['#3b5998', '#517ae2'],
        icon: 'fab fa-facebook-f',
        link: 'https://www.facebook.com/Try-Direct-193448794393123',
        className: 'facebook',
        disabled: true,
    }, {
        code: 'li',
        name: 'LinkedIn',
        color: ['#0077B5'],
        icon: 'fab fa-linkedin-in',
        link: 'https://www.linkedin.com/company/try-direct/',
        className: 'linkedin',
        margin: '0 0 0 0.2rem'
    }, {
        name: 'Twitter',
        icon: 'fab fa-twitter',
        className: 'twitter',
        link: 'https://twitter.com/TryDirect',
        color: ['#1da1f2'],
        excludeFromProviders: true,
        margin: '0.2rem 0 0 0.3rem'
    }, {
        code: 'bb', name: 'Bitbucket', color: ['#3c80f8'], icon: 'fa fa-bitbucket'
    }, {
        code: 'gh',
        name: 'Github',
        color: ['#24292e', '#d3e5f5'],
        icon: 'fab fa-github',
        link: 'https://github.com/trydirect',
        margin: '0 0 0 0.1rem',
        className: 'github',
    }, {
        name: 'Telegram',
        icon: 'fab fa-telegram-plane',
        color: ['#25a2e0'],
        link: 'https://t.me/trydirecthelp',
        linkContact: 'https://t.me/+37360775719',
        className: 'telegram',
        excludeFromProviders: true,
        margin: '0.1rem 0.3rem 0 0'
    }, {
        name: 'Discord',
        icon: 'fab fa-discord',
        color: ['#4985fa'],
        link: 'https://discord.gg/svjG29X',
        className: 'discord',
        excludeFromProviders: true,
        margin: '0 0 0 0.1rem'
    }, {
        name: 'Slack',
        icon: 'fab fa-slack',
        color: ['#2962ff'],
        link: 'https://optimum-team.slack.com/archives/C0147LXVBM1',
        className: 'slack',
        excludeFromProviders: true,
        margin: '0 0 0 0.1rem'
    }, {
        name: 'WhatsApp',
        icon: 'fab fa-whatsapp',
        color: ['#25d366'],
        link: 'https://optimum-team.slack.com/archives/C0147LXVBM1',
        linkContact: 'https://wa.me/37360775719',
        className: 'slack',
        excludeFromProviders: true,
        margin: '0 0 0 0.1rem'
    }, {
        code: 'gl', name: 'GitLab', color: ['#ed6c29'], icon: 'fa fa-gitlab'
    }, {
        code: 'aws', name: 'Amazon', color: ['#f09905'], icon: 'fa fa-amazon'
    }, {
        code: 'do', name: 'Digital Ocean', color: ['#0069ff'], icon: 'fab fa-digital-ocean'
    }, {
        code: 'lo', name: 'Linode', color: ['#35a56b'], icon: 'fab fa-linode'
    }, {
        code: 'gc', name: 'Google Cloud', color: ['#e44132'], icon: 'fab fa-google'
    }, {
        code: 'azu', name: 'Microsoft Azure', color: ['#479cd4'], icon: 'fab fa-microsoft'
    }
];
export const
    ADD_MODAL_LAYER = 'ADD_MODAL_LAYER',
    REMOVE_LAST_MODAL_LAYER = 'REMOVE_LAST_MODAL_LAYER',
    SET_MODAL_CONFIG = 'SET_MODAL_CONFIG',
    WIPE_MODAL_STATE = 'WIPE_MODAL_STATE'
;

export function addModalLayer({ // see descriptions in PropTypes from ModalLayout component
                                  component,
                                  onClose,
                                  appContainerBlur,
                                  modalBackgroundColor,
                                  layerBackgroundBlur,
                                  classNameLayer,
                                  classNameApp,
                                  classNameModal,
                                  preventClose,
                              }) {
    return {
        type: ADD_MODAL_LAYER,
        payload: [...arguments][0]
    }
}

export function removeLastModalLayer() {
    return { type: REMOVE_LAST_MODAL_LAYER, }
}

export function setModalConfig(data) { // this is ONLY for inner ModalLayout logic. Do not use it anywhere else
    return {
        type: SET_MODAL_CONFIG,
        payload: data,
    }
}

export function wipeModalState() {
    return { type: WIPE_MODAL_STATE, }
}

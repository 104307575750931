import React, { useEffect, useRef, useState } from "react";
import styles from "./UserTrigger.module.scss";
import ImgCDN from "../../../../../components/ImgCDN";
import { store } from "../../../../../redux/store";
import { addModalLayer, removeLastModalLayer } from "../../../../../redux/actions/ModalActions";
import UserMenu from "./UserMenu";

export default function UserTrigger({user, isMobile}) {

    const [reveal, setReveal] = useState(false);

    let userButton = useRef();
    let details = useRef();

    const handleClickOutsideDetails = e => {

        if (!window) return;

        const notUserButton = !e.target.closest('.' + userButton?.current?.classList[0]);
        const notDetails = !e.target.closest('.' + details?.current?.classList[0]);

        if (notUserButton && notDetails) setReveal(false);
    };

    useEffect(() => {
        return () => window.removeEventListener('mousedown', handleClickOutsideDetails);
    }, []);

    useEffect(() => {
        if (reveal) window.addEventListener('mousedown', handleClickOutsideDetails);
        if (!reveal) window.removeEventListener('mousedown', handleClickOutsideDetails);
    }, [reveal]);

    const detailsStatus = reveal && !isMobile ? ` ${styles['details--show']}` : '';
    const buttonUI = reveal ? ` ${styles['user__switch-button--active']}` : '';

    return (
        <div className={styles.user}>
            <button
                className={`${styles['user__switch-button']}${buttonUI}`}
                onClick={() => {
                    if (isMobile) {
                        setReveal(true);
                        store.dispatch(addModalLayer({
                            component: <UserMenu
                                onNavClick={() => setTimeout(() => {
                                    setReveal(false);
                                    store.dispatch(removeLastModalLayer());
                                }, 300)}
                                reveal={reveal}
                                isMobile={isMobile}
                            />,
                            classNameLayer: styles['user-mobile-modal-content'],
                            modalBackgroundColor: ['rgba(0, 0, 0, .2)'],
                            onClose: () => setReveal(false),
                        }))
                    } else setReveal(!reveal)
                }}
                ref={userButton}
                aria-label='Open user menu'
            >
                {
                    !user?.photo
                        ? <ImgCDN
                            src='/images/user.png'
                            alt='User photo'
                            className={styles['user__switch-button__no-image']}
                            aria-label='User photo'
                            draggable='false'
                            width={40}
                            height={40}
                            optimize
                        />
                        :
                        <img
                            className={styles['user__switch-button__image']}
                            src={user.photo + '?s=52'}
                            alt='User photo'
                            draggable='false'
                        />
                }
            </button>
            <div
                className={`${styles['details']}${detailsStatus}`}
                ref={details}
            >
                <UserMenu
                    onNavClick={isMobile ? undefined : () => setReveal(false)}
                    reveal={reveal}
                    isMobile={isMobile}
                />
            </div>
        </div>
    )
}
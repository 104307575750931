import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

export default function Switch({disabled, className, name, value, title, description, disabledMessage, onChange, style, tabIndex}) {

    const [animationsIsAllowed, setAnimationsIsAllowed] = useState(false);

    useEffect(() => {
        setTimeout(() => setAnimationsIsAllowed(true), 1000)
    }, []);

    const handleClick = e => {
        e.preventDefault();
        if (!!name) {
            onChange({
                target: {
                    value: !value,
                    name: name
                }
            })
        } else {
            onChange(!value)
        }
    };

    const switchedOn = value ? ' switch-button--checked' : '';
    const classFromParent = className ? ` ${className}` : '';
    const disabledClass = disabled ? ' switch-button--disabled' : '';

    return (
        <button
            style={style}
            type='button'
            onClick={disabled ? () => {} : handleClick}
            className={`switch-button${switchedOn}${disabledClass}${classFromParent}`}
            title={disabled && disabledMessage ? disabledMessage : undefined}
            tabIndex={disabled ? -1 : tabIndex || 0}
        >
            <div className='switch-button__title-container'>
                <div className='switch-button__title-container__title'>{!!title ? title : 'No title passed'}</div>
                {
                    description &&
                    <div className='switch-button__title-container__description'>{description}</div>
                }
            </div>
            <div className='switch-button__indicator' style={{'--not-allowed': animationsIsAllowed ? null : 'none'}}>
                <div className='switch-button__indicator__background'/>
                <div className='switch-button__indicator__point'/>
            </div>
        </button>
    )
}

Switch.propTypes = {
    value: PropTypes.bool,
    className: PropTypes.string,
    onChange: PropTypes.func,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    style: PropTypes.object,
    disabled: PropTypes.bool,
    disabledMessage: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    tabIndex: PropTypes.string,
};
let initialState = {
    currentPlan: null,
    selectedPlan: null,
};

import {
    SET_CURRENT_PLAN,
    SET_SELECTED_PLAN,
    WIPE_PAYMENT_DATA
} from '../actions/PaymentActions'

let paymentReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CURRENT_PLAN :
            return {
                ...state,
                currentPlan: action.payload
            };
        case SET_SELECTED_PLAN :
            return {
                ...state,
                selectedPlan: action.payload
            };
        case WIPE_PAYMENT_DATA :
            return {
                ...initialState,
                ...{currentPlan: state.currentPlan}
            };
        default:
            return state
    }
};

export default paymentReducer